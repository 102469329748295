@import "~@anytimefitness/hc-shared-styles/src/base/typography";
@import "~@anytimefitness/hc-shared-styles/src/base/colors";

.recipients {
	.react-confirm-alert {
		width: 782px;

		@include md-down {
			width: 100%;
			height: 100%;
		}

		.popup-body__title {
			margin-bottom: 8px;
		}
	}

	iframe {
		border: 1px solid map-get($new-color_blue, "normal");
		;
		border-right: none;
		height: 70vh;
		width: 100%;
	}

	&__button-container {
		margin-top: 15px !important;
	}
}