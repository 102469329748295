@import "~@anytimefitness/hc-shared-styles/src/base/colors";

.tabs {
  margin-bottom: 8px;

  &__list {
    display: flex;
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    box-shadow: inset 0 -1px 0 0 $new-tab__border-color;
  }

  &__item {
    border-bottom: 1px solid $new-tab__border-color;
    color: $new-tab__text-color;
    cursor: pointer;
    height: 36px;
    padding: 4px 8px;
    gap: 8px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.5px;
    text-align: left;
    text-wrap: nowrap;

    &_active {
      border-bottom: 3px solid $new-tab__border-color_active;
      color: $new-tab__text-color_active;
      font-weight: bold;
      outline: 0;
    }
  }
}

@media screen and (max-width: 743) {
  .tabs {
    &__list {
      float: left;
    }
  }
}