@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";
@import "~@anytimefitness/hc-shared-styles/src/utils/_media";

.testimonial-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 24px;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0px 16px;

    @include lg-down {
        padding: 0px;
    }

    & .arrow {
        display: inline-block;
    }

    &__group {
        width: 100%;
    }

    &__description {
        @include new-body1;
        color: map_get($new-color_grey, "dark");
    }

    &__item-wrapper {
        display: flex;
        flex-direction: column;
        padding: 0 84px;
        width: 100%;
    }

    &__item {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        padding: 24px 36px;

        @include lg-up {
            padding: 24px 132px;
        }
    }

    &__header {
        gap: 8px;
        width: 100%;
        @include new-h2;
        font-weight: $black_font-weight;
        text-align: center;
        color: map_get($new-color_blue, "normal");

        @include md-down {
            text-align: left;
        }
    }

    &__quotes-icon {
        width: 32px;
        height: 32px;
    }

    &__logo-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 0px;
        gap: 10px;
        height: 64px;
        width: 100%;
    }

    &__signature {
        display: flex;
        flex-direction: column;
        padding: 0px;
        gap: 8px;
        width: 100%;
    }

    &__signature-name {
        @include new-h3;
        font-weight: $black_font-weight;
        color: map_get($new-color_blue, "normal");

        @include md-down {
            font-weight: $bold_font-weight;
        }
    }

    &__signature-position {
        @include new-subtitle1;
        color: map_get($new-color_blue, "normal");
    }

    &__divider {
        width: auto;
        margin: 0;
        border-top: 1px solid map_get($new-color_blue, "normal");

        @include lg-up {
            margin: 0 108px;
        }
    }

    &__custom-slick-dots {
        width: auto;
        margin: 0 16px;
        padding-top: 36px;
        border: 0;
    }
}