@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.banking-info {
	.c-input__field {
		max-width: 350px;
	}
}

.bank-info-section {
	margin-bottom: 16px;

	p {
		margin: 0;
	}

	.payee-token-container {
		display: flex;
		align-items: center;
		gap: 60px;

		button {
			margin-top: 0;
		}
	}

	.bank-account-item {
		margin: 0;
		font-size: smaller;
		color: seagreen;
	}
}

.bank-info-current-text {
	@include new-subtitle2;
	font-weight: $bold_font-weight;
	color: map-get($new-color_blue, "normal");
}

.bank-info-text {
	@include new-body1;
	font-weight: $regular_font-weight;
	color: map-get($new-color_grey, "dark");

	&__error {
		color: map_get($new-color_beet, "pink");
	}
}

.warning-box {
	margin-top: 4px;
}