@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.club-locator-section {
  display: flex;
  flex-direction: column;
  padding: 0px;
  height: auto;
  position: relative;
  margin: 0 -64px;

  @include md-only {
    margin: 0 -32px;
  }

  @include md-down {
    margin: 0 -16px;
  }

  &__header-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 32px;
  }

  &__header {
    width: 100%;
    @include new-h2;
    font-weight: $black_font-weight;
    text-align: center;
    color: map_get($new-color_blue, "normal");
  }

  &__map-legend-wrapper {
    padding: 16px 32px;
    width: 100%;
    height: 60px;
    background-color: map_get($new-color_blue, "light_10%");
  }

  &__map-legend {
    display: none;

    @include md-up {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;
      padding: 16px 0px;
      width: 100%;
      height: 60px;
      background-color: map_get($new-color_blue, "light_10%");
      border-top: 1px solid map_get($new-color_blue, "light");
    }
  }

  &__map-legend-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    &:first-child {
      margin-left: 32px;
    }

    &:last-child {
      min-width: 0;
    }
  }

  &_nominate-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 68px;
    gap: 24px;
    width: 100%;
    background-color: map_get($new-color_blue, "light_10%");
    border-width: 1px 0px;
    border-style: solid;
    border-color: map_get($new-color_blue, "light");

    @include md-up {
      flex-direction: row;
      padding: 68px 134px 68px 64px;
    }
  }

  &__nominate-description {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    @include new-h4;
    color: map_get($new-color_blue, "normal");
  }

  &__nominate-btn-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    &_btn {
      max-width: 100%;
    }
  }
}