.image {
  display: block;

  &--not-active {
    pointer-events: none;
    text-decoration: none;
  }

  &--clickable {
    cursor: pointer;
  }
}
