@import "~@anytimefitness/hc-shared-styles/src/base/colors";

.facility-search-box {
    width: 100%;

    .MuiFormControl-root.MuiTextField-root,
    .MuiFormControl-root.MuiTextField-root {
        height: unset !important;
    }

    .MuiAutocomplete-clearIndicator MuiAutocomplete-clearIndicatorDirty {
        visibility: visible !important;
    }
}