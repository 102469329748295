@import "~@anytimefitness/hc-shared-styles/src/base/colors";

.error-summary {
    &__container {
        margin-top: 20px;
        color: $color_error;
    }

    &__title {
        color: $color_error;
    }

    &__error-message {
        color: $color_error;
    }
}