@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.topbar {
  padding: 10px 30px;
  width: 100%;
  background-color: map_get($new-color_green, "light");
  position: relative;
  display: none;

  &__content {
    height: 100%;
    font-size: 15px;
    text-align: center;

    &-error {
      @include new-subtitle2;
      color: map_get($new-color_white, "white") !important;
      font-weight: $bold_font-weight;
      text-transform: uppercase;

      .link {
        text-decoration: underline !important;
      }
    }
  }
}

.topbar:has([class*="error"]),
.topbar:has([class*="warning"]),
.topbar:has([class*="information"]) {
  display: block;
}

.topbar:has([class*="error"]) {
  background-color: map_get($new-color_beet, "dark") !important;
}