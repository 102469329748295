@import "~@anytimefitness/hc-shared-styles/src/utils/media";

.public_page {
  margin: 0px 64px;

  @include md-only {
    margin: 0px 32px;
  }

  @include md-down {
    margin: 0px 16px;
  }
}
