@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.arrow {
    position: absolute;
    color: map_get($new-color_blue, "normal");
    font-weight: $bold_font-weight !important;
    font-size: 38px !important;
    cursor: pointer;
    top: 50%;
    margin-top: -59px;
    z-index: 2;

    &:hover {
        color: map_get($new-color_blue, "darker_10%");
    }
}

.next-arrow {
    right: 18px;

    @include lg-down {
        right: -15px;
    }
}

.prev-arrow {
    left: 18px;

    @include lg-down {
        left: -15px;
    }
}

.custom-slick-dots {
    display: block;
    padding: 56px 0 0;
    list-style: none;
    text-align: center;
    margin: 0 84px;
    border-top: 1px solid map_get($new-color_blue, "normal");

    li {
        position: relative;
        display: inline-block;
        width: 16px;
        height: 16px;
        margin: 0 16px;
        padding: 0;
        cursor: pointer;
    }

    .slick-active .custom-dot {
        background: map_get($new-color_blue, "normal");
    }

    .custom-dot {
        cursor: pointer;
        display: block;
        width: 16px;
        height: 16px;
        background: $color_white;
        border: 2px solid map_get($new-color_blue, "normal");
        border-radius: 50%;

        &:hover {
            background: map_get($new-color_blue, "darker_10%") !important;
        }
    }
}