@import "~@anytimefitness/hc-shared-styles/src/base/typography";
@import "~@anytimefitness/hc-shared-styles/src/base/colors";

.new-editable-cell {
    display: flex;
    color: inherit;
    gap: 4px;

    &__plain-value {
        color: inherit;
    }

    &__button {
        border: none;
        background-color: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;

        &--green {
            color: map-get($new-color_blue, "normal");
        }

        &--red {
            color: map_get($new-color_beet, "pink");
        }

        &--blue {
            color: map-get($new-color_blue, "middle");

            &:hover {
                color: map-get($new-color_blue, "normal");
            }

            &:disabled {
                color: map-get($new-color_blue, "light");
            }
        }
    }

    &__button-icon {
        font-size: 16px !important;
        color: inherit;
    }

    .MuiFormControl-root.MuiTextField-root {
        height: 32px;
    }

    .MuiInputBase-root.MuiInputBase-root {
        height: 32px;
    }

    .MuiInputBase-input.MuiOutlinedInput-input {
        @include new-body2;
    }

    .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
        max-height: 32px;
    }

    .MuiOutlinedInput-input {
        padding: 6px 9px 8px 9px;
    }

    .mui-input-container {
        width: 100%;
        min-height: inherit;
        margin-top: 0px;
    }
}

#editableCellError .MuiTooltip-tooltip {
    background: map_get($new-color_beet, "pink") !important;
}

#editableCellError .MuiTooltip-arrow {
    color: map_get($new-color_beet, "pink");
}

#editableCellError .MuiTooltip-arrow .MuiTooltip-arrow::before {
    background: map_get($new-color_beet, "pink") !important;
}