@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.partners-section {
    display:  flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0px;

    & &__header{
        @include new-h2;
        color: map-get($new-color_blue, "normal");
        text-align: center;
        font-weight: $black_font-weight;
    }

    & &__items {
        margin-top: 32px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        @include md-only {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-auto-rows: 1fr;
            column-gap: 24px;
            row-gap: 24px;
            justify-items: center;
        }

        @include md-down {
            display: grid;
            grid-template-columns: none;
            row-gap: 24px;
            justify-items: center;
        }
    }

    & &__item {
        display: flex;
        margin-left: 12px;
        margin-right: 12px;
        width: 200px;
        height: 64px;
        justify-content: center;
        align-items: center;

        &:first-child{
            margin-left: 0;
        }

        &:last-child{
            margin-right: 0;
        }
    }
}