@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.mui-radio-group {
    $radio-color: map_get($new-color_blue, "normal");
    $radio-color_disabled: map_get($new-color_blue, "middle");

    .MuiFormLabel-root,
    .MuiFormLabel-root.Mui-focused {
        @include new-caption;
        color: $radio-color !important;
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .MuiFormControlLabel-label {
        @include new-body2;
        color: $radio-color;
    }

    .MuiSvgIcon-root {
        fill: $radio-color !important;
        width: 24px !important;
        height: 24px !important;
    }

    .MuiRadio-root {
        padding: 0;
        margin-right: 8px;
    }

    .MuiFormControlLabel-root {
        margin-left: 0;
        margin-right: 24px;
    }

    .MuiFormControlLabel-label.Mui-disabled {
        color: $radio-color_disabled;
    }

    .Mui-disabled {
        .MuiSvgIcon-root {
            fill: $radio-color_disabled !important;
        }
    }
}