@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.resign-agreement-popup {
    .react-confirm-alert {
        width: 864px;
    }
}

.legal-contract {
    .equipment-and-facility-policies {
        &__container {
            @include md-only {
                height: 280px;
            }

            @include md-down {
                height: 230px;
            }
        }
    }
}