@import "~@anytimefitness/hc-shared-styles/src/components/info-box";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.prtl-table {
    .info-box__table-container {
        display: block;
        max-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        margin-top: 30px;

        .info-box__table {
            width: 100%;
        }
    }

    .info-box__table-cell_center-text {
        text-align: center;
    }

    .info-box__table-controls-container {
        .info-box__table-size-selector-container {
            .c-select__control {
                min-width: 71px;
            }
        }

        .table-pager {
            &__seek-first {
                background-image: url(../../assets/icons/ic-action-double-arrow-left.svg);

                &:hover {
                    background-image: url(../../assets/icons/ic-action-double-arrow-left-hover.svg);
                }

                &:disabled {
                    background-image: url(../../assets/icons/ic-action-double-arrow-left-disabled.svg);
                }
            }

            &__seek-prev {
                background-image: url(../../assets/icons/ic-action-arrow-left.svg);

                &:hover {
                    background-image: url(../../assets/icons/ic-action-arrow-left-hover.svg);
                }

                &:disabled {
                    background-image: url(../../assets/icons/ic-action-arrow-left-disabled.svg);
                }
            }

            &__seek-next {
                background-image: url(../../assets/icons/ic-action-arrow-right.svg);

                &:hover {
                    background-image: url(../../assets/icons/ic-action-arrow-right-hover.svg);
                }

                &:disabled {
                    background-image: url(../../assets/icons/ic-action-arrow-right-disabled.svg);
                }
            }

            &__seek-last {
                background-image: url(../../assets/icons/ic-action-double-arrow-right.svg);

                &:hover {
                    background-image: url(../../assets/icons/ic-action-double-arrow-right-hover.svg);
                }

                &:disabled {
                    background-image: url(../../assets/icons/ic-action-double-arrow-right-disabled.svg);
                }
            }
        }
    }
}

.info-box {
    @include new-body1;
    position: relative;
    padding: 15px 24px !important;
    background-color: map_get($new-color_white, "white");
    height: 100%;

    &--bordered {
        box-shadow: none !important;
        border-radius: 4px;
        border: 1px solid map_get($new-color_blue, "light");
    }

    &__row {
        margin: 0 5px !important;
        padding-bottom: 4px;

        &--bold {
            font-weight: bold;
        }

        &--bottom-border {
            border-bottom: 1px solid map_get($color_grey, "light-gray");
        }

        @include md-down {
            flex-direction: column;
        }
    }

    &__cell {
        margin: 0 !important;

        &--right {
            justify-content: right;
        }

        &:first-child {
            font-weight: inherit !important;
        }
    }

    &--grid {
        padding: 0px !important;
    }

    &--grid__row {
        margin: 0px !important;
    }
}