@import "~@anytimefitness/hc-shared-styles/src/base/colors";

.input-file {
	&__note {
		display: flex;
		gap: 8px;
		padding-bottom: 8px;
	}

	&__note-icon {
		font-size: 16px !important;
		color: map_get($new-color_blue, "normal");
	}

	&__note-text {
		font-size: 10px;
		line-height: 16px;
	}
}

input[type=file]::file-selector-button {
	border-radius: 5px;
	border: 1px solid map_get($new-color_grey, "light");
	background-color: map_get($new-color_white, "white")
}