@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "../../styles/animation";

.spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex: 1;
  position: absolute;
  z-index: 19;
  background-color: map_get($new-color_blue, "lighter_50%");

  &__inner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;
  }

  &__message-text {
    margin-top: 50px
  }

  &__animation {
    color: map_get($color_blue, "primary-blue");
    font-size: 10px;

    width: 120px;
    height: 120px;
    border-radius: 50%;
    background:
      radial-gradient(farthest-side, map_get($new-color_blue, "normal") 94%, #0000) top/12px 12px no-repeat,
      conic-gradient(#0000 30%, map_get($new-color_blue, "normal"));
    mask: radial-gradient(farthest-side, #0000 calc(100% - 12px), #000 0);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 12px), #000 0);
    animation: new_circular_loader 1s infinite linear;
    aspect-ratio: 1;
  }
}