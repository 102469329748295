 @import "~@anytimefitness/hc-shared-styles/src/base/colors";

 .form-group {
 	&__label {
 		display: block;
 		margin-bottom: 15px;
 		color: $form-element__label-color;
 	}

 	.btn {
 		margin-top: 30px;
 	}

 	.validation-summary {
 		color: map_get($color_red, 'text-red');
 		margin-top: 30px;

 		&--no-margin {
 			margin: 0;
 		}
 	}
 }