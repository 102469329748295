@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.react-confirm-alert-body {
	width: auto;
	border-radius: 4px;
	padding: 24px 32px 48px 32px;

	@include md-down {
		border-radius: 0;
		padding: 24px 24px 82px 24px;
	}
}

.popup-body {
	&__title {
		@include new-h3;
		color: map_get($new-color_blue, "normal");
		font-weight: $bold_font-weight;
		margin-bottom: 16px;
	}

	&__body .message {
		@include new-body1;
		color: map_get($new-color_grey, "dark");
		margin: 0;
	}

	&__close-icon-button {
		margin-bottom: 8px;
		background-color: transparent;
		border: none;
		color: map_get($new-color_blue, "normal");
		display: flex;
		cursor: pointer;
		padding: 0;
		margin-left: auto;

		&:hover * {
			color: map_get($new-color_blue, "normal");
		}

		@include md-down {
			margin-bottom: 88px;
		}
	}

	&__button-group {
		display: flex;
		justify-content: flex-start;
		margin-top: 32px;
		gap: 8px;

		@include md-down {
			gap: 16px;
			flex-direction: column;
		}
	}
}