@import "~@anytimefitness/hc-shared-styles/src/utils/media";

.general-info {
	&__banner {
		height: 630px;
		display: none;
		float: right;

		@include md-up {
			display: block;
		}
	}
}