@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.contact-us {
    display: flex;
    flex-direction: row;
    padding-bottom: 128px;

    @include lg-down {
        flex-direction: column;
        flex-wrap: wrap;
        padding-left: 160px;
        padding-right: 160px;
    }

    @include md-down {
        padding-left: 16px;
        padding-right: 16px;
    }

    &__connect {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 64px;
        flex-grow: 1;

        @include lg-down {
            padding-top: 48px;
        }

        @include md-down {
            padding-top: 32px;
        }
    }

    &__connect-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 32px;
        background: map_get($new-color_blue, "light_10%");
        border: 1px solid map_get($new-color_green, "light");
        border-radius: 8px;
        width: 566px;

        @include lg-down {
            width: 100%;
        }
    }

    &__connect-form-title {
        @include new-h2;
        font-weight: $black_font-weight;
        color: map_get($new-color_blue, "normal");
        display: flex;
        align-items: center;
        text-align: center;
        margin-bottom: 24px;
    }

    &__connect-form-fields {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__contacts-wrapper {
        display: flex;
        flex-direction: column;
        padding: 256px 24px 0px 24px;
        flex-grow: 1;

        @include lg-down {
            padding: 104px 0px 0px 0px;
        }
    }

    &__link {
        text-decoration-line: underline;
        color: map_get($new-color_blue, "normal");
    }

    &__contacts-title {
        @include new-h2;
        font-weight: $black_font-weight;
        color: map_get($new-color_blue, "normal");
    }

    &__contacts-subtitle {
        @include new-h3;
        font-weight: $bold_font-weight;
        color: map_get($new-color_blue, "normal");
    }

    &__contacts-description-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        gap: 28px;

        @include lg-down {
            gap: 4px;
        }
    }

    &__contacts-description {
        @include new-h4;
        color: map_get($new-color_grey, "dark");
        display: flex;
        flex-direction: column;

        @include lg-down {
            flex-direction: row;
            gap: 8px;
            flex-wrap: wrap;
        }
    }

    &__contacts {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 32px;
    }

    &__form-group {
        width: 100%;
    }

    & &__button {
        margin-top: 32px;
    }

    &__captcha {
        margin-top: 68px;
    }
}