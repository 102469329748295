@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.upload-results-container {
    padding: 32px;

    @include md-down {
        padding: 32px 16px;
    }
}

.upload-recipients-results {
    &__header {
        @include new-h2;
        font-weight: $black_font-weight;
        color: map_get($new-color_blue, "normal");
        margin-bottom: 16px;

        span {
            margin-right: 10px;
            color: map_get($new-color_blue, "normal");
            cursor: pointer;
        }
    }

    &__title {
        @include new-h3;
        font-weight: $bold_font-weight;
        color: map_get($new-color_blue, "normal");
    }

    &__title-note {
        @include new-subtitle2;
        font-weight: $bold_font-weight;
        font-style: italic;
        color: map_get($new-color_blue, "middle");
        margin-top: 4px;
    }

    &__footer {
        padding-top: 32px;
    }
}