@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";
@import "~@anytimefitness/hc-shared-styles/src/utils/utils";

.billing_info {
    &__title {
        @include new-h3;
        font-weight: $bold_font-weight;
        color: map_get($new-color_blue, "normal");
        margin-top: 24px;
    }

    &__subtitle {
        margin: 8px 0 16px;
    }

    &__box_icon {
        color: map_get($new-color_blue, "normal");
    }

    &__bank_accounts_container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__error {
        background: map_get($new-color_beet, "lighter");
        border-color: map_get($new-color_beet, "light") !important;
        height: auto !important;
    }

}