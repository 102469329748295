.forgot-popup {
    display: flex;
    flex-direction: column;
    gap: 32px;
    border-radius: 8px;
    width: 100%;
    margin-top: 40px;

    &__form-group {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__text-fields {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__button-wrapper {
        margin-top: 32px;
    }

    &__result-buttons-wrapper {
        display: flex;
        flex-direction: row;
        gap: 16px;
        margin-top: 32px;
    }

    &__captcha {
        margin-top: -8px
    }
}