@import "~@anytimefitness/hc-shared-styles/src/base/typography";
@import "~@anytimefitness/hc-shared-styles/src/base/colors";

.keyfobs-employees-list {
    padding-top: 32px;
    width: 100%;
    overflow-x: auto;
    height: 100%;

    &__table {
        height: 576px
    }

    &__title-container {
        display: flex;
        align-items: center;
        padding-bottom: 24px
    }

    &__title {
        @include new-h3;
        font-weight: $bold_font-weight;
        color: map_get($new-color_blue, "normal");
        width: 100%;
    }

    &__button-container {
        padding-left: 16px;

        .btn_new {
            @include new-body2;
            font-weight: $black_font-weight;
            min-height: 40px;
            height: 40px;
            padding: 8px 24px 8px 16px;
        }

    }

    &__button-container--left {
        display: flex;
        justify-content: flex-end;

        .btn_new {
            min-height: 48px;
            height: 48px;
            padding: 0 16px;

            @include md-down {
                width: auto;
            }
        }
    }

    &__button-action-container {
        display: flex;
        gap: 8px
    }

    &__button-action {
        border: none;
        background-color: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
        color: map-get($new-color_blue, "middle");

        &:hover {
            color: map-get($new-color_blue, "normal");
        }

        &:disabled {
            color: map-get($new-color_blue, "light");
        }
    }

    &__button-action-icon {
        color: inherit;
    }
}