@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.filter-box {
    &__selection {
        height: calc(100% - 103px);
        position: absolute;
        top: 79px;
        background-color: $color_white;
        padding: 0 16px;
        border: 1px solid map_get($new-color_blue, "light");
        border-radius: 4px;
        left: 0;
        margin: 0 24px;
        width: calc(100% - 48px);
        max-width: 384px;

        @include md-down {
            height: 100vh;
        }

        ul {
            list-style-type: none;
            padding-left: 0;

            li .MuiFormControlLabel-label {
                @include new-body2;
                padding-top: 2px;
            }

            li .MuiFormControlLabel-root {
                display: inline-flex;
                position: relative;
                align-items: flex-start;
                vertical-align: top;
                justify-content: flex-start;
            }
        }
    }

    &__plan-option {
        padding-left: 0;
        overflow-y: scroll;
        height: calc(100% - 132px);

        @include md-down {
            height: calc(50% - 132px);
        }
    }

    &__list-item {
        padding-bottom: 8px;
    }

    &__item-option {
        li {
            padding-left: 26px;
        }
    }

    &__selected {
        overflow-y: scroll;
        height: calc(100% - 1px);
        display: contents;
        border-bottom: 1px solid map_get($new-color_blue, "light");
        max-width: 384px;

        ul {
            list-style-type: none;
            padding-left: 0;
        }

        .icon-caption {
            float: left;
            display: flex;
            align-items: center;
            vertical-align: middle;
            justify-content: center;
            margin-right: 8px;
            word-break: normal;

            &__text {
                @include new-caption;
            }
        }
    }

    &__close-icon {
        cursor: pointer;
        color: map_get($new-color_blue, "normal");
    }

    &__title {
        @include new-subtitle1;
        font-weight: $bold_font-weight;
        color: map_get($new-color_blue, "normal");
    }

    &__title-container {
        padding: 8px 0;
        position: sticky;
        height: 28px;
        top: 0;
        background-color: $color_white;

        h4 {
            background-color: $color_white;
        }
    }

    &__action-buttons {
        padding: 0 8px 16px 8px;
        position: sticky;
        bottom: 38px;
        left: 0;
        background-color: $color_white;

        @include md-down {
            bottom: 0;
        }
    }

    &__action-button-item {
        background-color: $color_white;

        @include md-up {
            max-width: 50%;
        }

        @include md-down {
            width: 100%;
            margin: auto;
            padding-bottom: 16px;
        }

    }

    &__control-field {
        display: inline-flex;
        align-items: flex-start;
        vertical-align: top;
        justify-content: flex-start;
        padding: 0 8px;

        .MuiSvgIcon-root {
            font-size: 20px;
        }

        .MuiSvgIcon-fontSizeSmall {
            font-size: 14px;
        }
    }
}

.show-button {
    @include new-subtitle2;
    display: block;
    float: right;
}

.reset-button {
    @include new-h4;
    text-decoration: underline;
}

.show-button,
.reset-button {
    font-weight: $bold_font-weight;
    color: map_get($new-color_blue, "normal");
    text-transform: uppercase;
    cursor: pointer;
}