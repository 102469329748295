@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.popup-confirmation-panel {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border: 1px solid map_get($new-color_blue, "light");
    background: map_get($new-color_blue, "lighter");
    margin-top: 16px;

    .processed {
        color: map_get($new-color_blue, "normal");
        /* Overline/Black */
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 900;
        line-height: 16px;
        /* 133.333% */
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }

    .uploaded {
        color: map_get($new-color_blue, "normal");
        /* Overline/Black */
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 900;
        line-height: 16px;
        /* 133.333% */
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }

    .failed {
        color: map_get($new-color_beet, "pink");
        /* Overline/Black */
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 900;
        line-height: 16px;
        /* 133.333% */
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }
}

.upload-member-recipients {
    &__header {
        @include new-h2;
        font-weight: $black_font-weight;
        color: map_get($new-color_blue, "normal");
        margin-bottom: 16px;

        span {
            margin-right: 10px;
            color: map_get($new-color_blue, "normal");
            cursor: pointer;
        }
    }

    &__title {
        @include new-h3;
        font-weight: $bold_font-weight;
        color: map_get($new-color_blue, "normal");
        margin-bottom: 16px;
    }

    &__subtitle {
        @include new-subtitle1;
        font-weight: $bold_font-weight;
        color: map_get($new-color_blue, "middle");
        margin-top: 8px;
    }

    &__title-note {
        @include new-subtitle2;
        font-weight: $bold_font-weight;
        font-style: italic;
        color: map_get($new-color_blue, "middle");
        margin-top: 4px;
    }

    &__subtitle-label {
        @include new-subtitle2;
        font-weight: $regular_font-weight;
        color: map_get($new-color_grey, "dark");
        margin-top: 16px;
        ;
    }
}