@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.text-section {
    width: 100%;
    display: flex;
    align-self: stretch;

    &__content-container {
        align-items: center;
        text-align: center;
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 16px;
        
        @include md-down{
            text-align: left;
        }

        &--column-on-large-screen {
            @include lg-up {
                align-items: flex-start;
                text-align: left;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 32px;
            }
        }

        &--column {
            @include md-up {
                align-items: flex-start;
                text-align: left;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 32px;
            }
        }

        &--centered {
            padding: 0px 224px;
        
            @include lg-down {
              padding: 0px;
            }
          }
    }

    &__title {
        @include new-h2;
        color: map_get($new-color_blue, "normal");
        font-weight: $black_font-weight;
        flex-basis: 100%;
    }

    &__text {
        @include new-h3;
        flex-basis: 100%;
    }
}