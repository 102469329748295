@import "~@anytimefitness/hc-shared-styles/src/utils/media";
@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.period {
    &__container {
        max-width: 564px;
        background-color: map_get($new-color_blue, "lighter");
        border: 1px solid map_get($new-color_blue, "light");
        border-radius: 4px;
        padding: 0 8px 0 16px;
    }

    &__field {
        @include md-up {
            max-width: 216px !important;
        }
    }

    &__button {
        padding: 0 0 8px 0 !important;
    }
}