@import "~@anytimefitness/hc-shared-styles/src/utils/mixins";

.additional-details {
	$icon-size: 30px;
	display: flex;

	.card-info & {
		margin-top: 30px;
	}

	&__icon {
		@include size($icon-size);
		margin-right: 10px;
		display: flex;
	}

	&__title {
		min-height: $icon-size;
		margin-bottom: 30px;
	}

	&__description {
		min-height: $icon-size;
		font-size: 14px;
		line-height: 1.7;
	}
}