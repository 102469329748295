@import "../../styles/variables";
@import "~@anytimefitness/hc-shared-styles/src/utils/media";
@import "~@anytimefitness/hc-shared-styles/src/utils/mixins";
@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.sidebar {
	background-color: $sidebar__bg-color;

	&__container {
		background-color: $sidebar__bg-color;
		width: $sidebar-width;
		font-family: Poppins;

		&--hidden {
			@include lg-down {
				width: 0;
			}
		}
	}

	&__list {
		margin: 0;
		padding: 0;
		border: 0;
	}

	&__items {
		position: sticky;
		top: 88px;

		&--hidden {
			@include lg-down {
				display: none !important;
			}
		}

		@include lg-down {
			top: 64px;
		}
	}

	&__link {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 25px;
		line-height: 18px;
		width: $sidebar-width;
		height: $sidebar-width;
		background-color: $sidebar__bg-color;
		color: $sidebar__link-color;
		cursor: pointer;
		text-align: center;
		opacity: 0.75;
		transition: background-color 0.15s ease-in, opacity 0.15s;

		&:hover,
		&:focus {
			background-color: darken($sidebar__bg-color, 8%);
			opacity: 0.9;
		}

		&_active {
			background-color: $sidebar__link-bg-color_active !important;
			font-weight: bold;
			cursor: default;
			opacity: 1;
		}
	}

	&__label {
		align-self: stretch;
		color: $sidebar__link-color;
		word-spacing: 100vw;
	}

	&__link-icon {
		margin-bottom: 10px;
	}

	&__tooltip {
		@include lg-up {
			display: none;
		}
	}

	&__chevron-icon {
		color: $sidebar__link-color;
	}

	&__chevron-icon-button {
		color: $sidebar__link-color;
		@include new-caption;
	}

	&__toggle-btn {
		display: flex;
		position: absolute;
		left: 130px;
		z-index: 1;
		padding: 16px 0;
		background-color: $sidebar__bg-color;
		border-width: 1px 1px 1px 0px;
		border-style: solid;
		border-color: #BDCBD5;
		border-radius: 0px 4px 4px 0px;
		width: fit-content;

		&-reset {
			left: 0;
		}

		@include lg-up {
			display: none;
		}
	}
}