@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";
@import "~@anytimefitness/hc-shared-styles/src/utils/media";

.metrics-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;

  & &__description {
    @include new-h4;
    margin-top: 32px;
    color: map_get($new-color_grey, "dark");
  }

  & &__items {
    display: grid;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    padding-top: 32px;

    @include md-down {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      align-items: flex-start;
      gap: 16px;
    }

    @include lg-up {
      grid-column-gap: 32px;
      grid-row-gap: 32px;
    }

    &--columns-2 {
      grid-template-columns: repeat(2, 1fr);
    }

    &--columns-3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  & &__item {
    flex-grow: 1;
    flex-basis: 0;
    background-color: map_get($new-color_blue, "light_10%");
    padding: 24px 32px 40px;
    align-self: stretch;
  }

  & &__item-description {
    margin-top: 16px;
  }

  & &__item-header {
    color: map_get($new-color_blue, "normal");
    @include new-h3;
    font-weight: $bold_font-weight;
  }

  & &__item-description {
    @include new-h4;
    color: map_get($new-color_blue, "normal");
    font-weight: $regular_font-weight;
    margin-top: 16px;
  }
}