@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.visibility-icon {
	color: map_get($new-color_blue, "normal") !important;
	cursor: pointer;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
	transform: translate(20px, -12px) scale(1);
	color: map_get($new-color_blue, "normal") !important;
	@include new-caption;
}

.MuiFormLabel-root {
	color: map_get($new-color_blue, "normal");
	@include new-body1;
	padding-top: 4px;
}

.MuiInputLabel-outlined {
	transform: translate(16px, 10px) scale(1);
}

.MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
	border-color: map_get($new-color_blue, "light");
	padding: 0 14px !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
	border-color: map_get($new-color_blue, "normal") !important;
}

.MuiFormControl-root:hover .MuiFormLabel-root.MuiInputLabel-outlined {
	color: map_get($new-color_blue, "normal") !important;
}

.MuiInputBase-root {
	height: 56px;
	width: 100%;
	color: map_get($new-color_blue, "normal") !important;
	@include new-body1;
	background-color: map_get($new-color_white, "white");
}

.MuiInputBase-input {
	color: map_get($new-color_blue, "normal");
	@include new-body1;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
	border: 1px solid map_get($new-color_beet, "dark") !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink.Mui-error,
.MuiInputLabel-outlined.MuiInputLabel-shrink.Mui-error span {
	color: map_get($new-color_beet, "dark") !important;
}

.MuiFormLabel-asterisk {
	color: map_get($new-color_blue, "normal") !important;
	margin-left: -3px;
}

.MuiSelect-outlined.MuiOutlinedInput-input {
	padding: 14px 16px !important;
}

.MuiFormLabel-root.Mui-error {
	color: map_get($new-color_beet, "dark") !important;
}

.MuiFormControl-root .MuiFormHelperText-root {
	@include new-caption;
	color: map_get($new-color_beet, "dark") !important;
	padding: 0px;
}

.MuiInputBase-multiline.MuiOutlinedInput-multiline {
	padding: 14px 16px;
	height: auto;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: map_get($new-color_blue, "normal") !important;
	border-width: 1px !important;
}

.MuiMenuItem-root {
	padding: 8px 8px 8px 16px !important;
}

.MuiSelect-select:focus {
	background-color: map_get($new-color_white, "white");
}

.MuiPaper-elevation8 {
	border-width: 1px;
	border-style: solid;
	border-color: map_get($new-color_blue, "normal");
	border-radius: 0px 0px 4px 4px;
}

.MuiPopover-root {
	touch-action: none;
	overflow: hidden;
}

.MuiMenuItem-root.MuiMenuItem-root {
	@include new-body1;
	color: map_get($new-color_blue, "normal") !important;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
	background: map_get($new-color_blue, "light_10%") !important;
}

.MuiListItem-root.Mui-selected::after {
	font-family: 'Material Icons Outlined';
	content: "done";
	position: absolute;
	right: 14px;
}

.MuiSelect-root.Mui-focused,
.MuiPaper-elevation1 {
	border-width: 0px 1px 1px 1px;
	border-style: solid;
	border-color: map_get($new-color_blue, "normal");
	border-radius: 0px 0px 4px 4px;
	box-shadow: none !important;
}

.MuiAutocomplete-paper {
	margin: 0 !important;
}

.MuiAutocomplete-option[data-focus="true"] {
	background: map_get($new-color_blue, "light_10%") !important;
}

.MuiAutocomplete-option.MuiAutocomplete-option {
	@include new-body1;
	color: map_get($new-color_blue, "normal") !important;
}

.MuiAutocomplete-noOptions.MuiAutocomplete-noOptions {
	@include new-body1;
	color: map_get($new-color_blue, "normal") !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
	padding-left: 8px !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment {
	right: 8px !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
	padding: 8px 6px !important;
}

.MuiSvgIcon-root {
	fill: map_get($new-color_blue, "normal");
	width: 24px;
	height: 24px;
}

.MuiAutocomplete-clearIndicatorDirty {
	margin-left: 8px !important;
	visibility: visible !important;
}

.MuiInputAdornment-positionStart,
.MuiInputAdornment-positionStart .MuiTypography-body1 {
	font-size: 1.6rem !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill::first-line {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
	-webkit-text-fill-color: map_get($new-color_blue, "normal");
}

.MuiInputBase-root.MuiInputBase-root fieldset legend {
	@include new-caption;
}

.mui-input-container {
	width: 100%;
	min-height: 80px;
	margin-top: 24px;
}

.MuiPickersBasePicker-pickerView {
	height: 280px;
	min-height: 280px;
	max-width: unset !important;
}

.MuiPickersCalendarHeader-switchHeader {
	margin-bottom: 8px;
	height: 24px;
	margin-top: 0;
}

.MuiPickersCalendarHeader-transitionContainer {
	height: 24px;

	p {
		@include new-caption;
		text-align: center;
		color: map_get($new-color_blue, "normal");
		margin-top: 4px;
	}

}

.MuiPickersCalendarHeader-daysHeader {
	max-height: 32px;
	height: 32px;
	color: map_get($new-color_blue, "middle");
	font-size: 12px;
}

.MuiPickersCalendarHeader-dayLabel {
	color: map_get($new-color_blue, "middle");
	font-size: 12px;
}

.MuiPickersCalendar-transitionContainer {
	margin-top: 8px;
	min-height: 196px;
}

.MuiPickersDay-day {
	span p {
		@include new-caption;
		text-align: center;
	}
}

.MuiIconButton-label {
	color: inherit;
}

.MuiPickersDay-day.MuiPickersDay-daySelected {
	width: 32px;
	height: 32px;
	background-color: map_get($new-color_blue, "normal");

	span p {
		color: map_get($new-color_white, "white");
	}
}