@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.mui_button_container {
    padding: 24px 8px 0 8px;

    &_no_padding {
        padding: 0;
    }

    @include md-down {
        width: 100%;
        padding-top: 8px;
    }
}