@import "~@anytimefitness/hc-shared-styles/src/base/typography";
@import "~@anytimefitness/hc-shared-styles/src/base/colors";

.info-page {
  padding: 64px 136px 128px;

  @include md-only {
    padding: 48px 64px 104px;
  }

  @include md-down {
    padding: 32px 16px 88px;
  }

  $secondary-content-width: 352px;

  &__title {
    @include new-h2;
    font-weight: $bold_font-weight;
    color: map_get($new-color_blue, "normal");
    margin-bottom: 16px;
  }

  &__subtitle {
    @include new-h3;
    margin: 16px 0;
    width: 100%;
    color: map_get($new-color_grey, "dark");

    &-content {
      margin-top: 5px;
    }
  }

  &__content {
    display: flex;
    gap: 48px;

    @include md-down {
      gap: 32px;
    }

    @include lg-down {
      flex-direction: column-reverse;
    }

    .link {
      @include new-body1;
      text-decoration-line: underline;

      &--blue {
        color: map_get($new-color_blue, "normal");
      }

      &--overflow-wrap {
        overflow-wrap: anywhere;
      }
    }
  }

  &__primary-content {
    display: flex;
    flex-direction: column;
    gap: 48px;

    @include lg-down {
      margin-right: 0;
    }

    @include md-down {
      gap: 32px;
    }

    &-section {
      &-title {
        @include new-h3;
        color: map_get($new-color_blue, "normal");
        font-weight: $bold_font-weight;
        margin-bottom: 16px;
      }

      &-subtitle {
        @include new-body1;
        color: map_get($new-color_blue, "normal");
        font-weight: $bold_font-weight;
        margin: 16px 0;

        &--regular {
          color: map_get($new-color_grey, "dark");
          font-weight: $regular_font-weight;

          &--italic {
            font-style: italic;
          }
        }
      }

      &-paragraph {
        @include new-body1;
        color: map_get($new-color_grey, "dark");

        &--bold {
          font-weight: $bold_font-weight;
        }

        &:not(:first-child) {
          margin-top: 16px;
        }
      }

      &-list {
        margin: 16px 0;

        &-item {
          @include new-body1;
          color: map_get($new-color_grey, "dark");

          &-title {
            color: map_get($new-color_blue, "normal");
            font-weight: $bold_font-weight;
          }

          &--disc {
            list-style-type: disc;
          }
        }

        &--no-bullets {
          list-style-type: none;
          padding-left: 0;
        }
      }

      &-address {
        @include new-body1;
        margin: 24px 0;
      }

      &-table {
        margin: 16px 0;
        table-layout: fixed;
        width: 100%;

        ul {
          list-style-type: disc;
        }

        &-header-row {
          @include new-subtitle1;
          font-weight: $bold_font-weight;

          &-cell {
            @include md-down {
              padding: 16px 8px 24px 8px;
            }

            color: map_get($new-color_blue, "normal");
            border: 1px solid map_get($new-color_blue, "light");
            padding: 16px 16px 24px 16px;
            text-align: left;

            &--break-all {
              @include md-down {
                word-break: break-all;
              }
            }
          }
        }

        &-body-row {
          @include new-body1;

          &-header-cell {
            text-align: left;

            @include md-down {
              padding: 16px 8px 24px 8px;
            }

            border-style: solid;
            border-color: map_get($new-color_blue, "light");
            border-width: 0px 1px 1px;
            padding: 16px 16px 24px 16px;

            &-content {
              color: map_get($new-color_grey, "dark");

              &--bold {
                font-weight: $bold_font-weight;
              }
            }
          }

          &-cell {
            @include md-down {
              padding: 16px 8px 24px 8px;
            }

            border-style: solid;
            border-color: map_get($new-color_blue, "light");
            border-width: 0px 1px 1px;
            padding: 16px 16px 24px 16px;

            &-content {
              color: map_get($new-color_grey, "dark");

              &--bold {
                font-weight: $bold_font-weight;
              }
            }

            &--break-all {
              @include md-down {
                word-break: break-all;
              }
            }
          }
        }
      }
    }
  }
}