@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.form-step {

    &__content-wrapper {
        width: 100%;
        margin: auto;
        border: 1px solid map_get($new-color_green, "light");
        border-radius: 8px;
        background: map_get($new-color_blue, "light_10%");
        padding: 24px 32px 40px;

        &--narrow {
            @include md-only {
                width: 616px;
            }
        }

        @include md-down {
            width: 100%;
            padding: 24px 16px 40px;
        }

        &--collapsed {
            max-height: 76px;
        }

    }

    &__content {
        display: none;

        &--expanded {
            display: block;
        }
    }

    &__progress {
        float: right;
        padding-top: 5px;
        display: none;

        &--complete {
            display: block;
        }
    }

    &__step-info {
        @include new-h3;
        font-weight: $bold_font-weight;
        color: map_get($new-color_blue, "middle");

        &--selected {
            color: map_get($new-color_blue, "normal");
            margin-bottom: 16px;
        }
    }

    &__forms {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__clickable {
        cursor: pointer;
    }
}