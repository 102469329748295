@import "~@anytimefitness/hc-shared-styles/src/base/typography";
@import "~@anytimefitness/hc-shared-styles/src/base/colors";

.member-list {
    max-width: 1920px;
    margin: 0 auto;

    &__filters-panel {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 16px;
        max-width: 1098px;
        height: 104px;

        @include md-down {
            align-items: center;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            max-width: 564px;
            height: 296px;
        }
    }

    &__members-search-bar {
        max-width: 472px;

        @include md-down {
            max-width: unset;
        }
    }

    &__facility-search-box {
        max-width: 398px;

        @include md-down {
            max-width: unset;
        }
    }

    &__bulk_options {
        flex: 0 0;
        width: max-content;
        position: relative;

        button {
            background-color: map_get($new-color_blue, "normal");
            border-right: 0 !important;
            color: $color_white !important;

            span {
                color: $color_white !important;
            }
        }

        .split-popper {
            transform: translate3d(0px, 72px, 0px) !important;
            width: 100%;
            z-index: 10;
            background-color: map-get($new-color_white, 'white');
        }
    }

    &__title {
        @include new-h2;
        font-weight: $black_font-weight;
        color: map_get($new-color_blue, "normal");
    }

    &__button-action-container {
        display: flex;
        gap: 8px
    }

    &__button-action {
        border: none;
        background-color: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
        color: map-get($new-color_blue, "middle");

        &:hover {
            color: map-get($new-color_blue, "normal");
        }

        &:disabled {
            color: map-get($new-color_blue, "light");
        }
    }

    &__button-action-icon {
        color: inherit;
    }
}