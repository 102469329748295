@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.split-button {
    .MuiButtonGroup-root {
        margin-top: 16px;
        margin-bottom: 16px;
        height: 56px;
        border-radius: 1px;

        .MuiButton-root {
            @include new-body1;
            color: rgba(0, 0, 0, 0.87);
            padding: 6px 16px;
            min-width: 64px;
            box-sizing: border-box;
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            border-radius: 4px;
        }
    }

    .MuiButtonGroup-contained {
        box-shadow: none;
    }

    .MuiButton-contained {
        width: max-content;
        box-shadow: none;
        background-color: map_get($new-color_blue, "normal");

        &.Mui-disabled {
            background-color: map_get($new-color_blue, "middle") !important;
        }

        &:hover {
            box-shadow: none;
            background-color: map_get($new-color_blue, "darker_10%");
        }

        span {
            font-size: 16px;
            font-weight: 900;
        }
    }
}