@import "~@anytimefitness/hc-shared-styles/src/utils/mixins";
@import "~@anytimefitness/hc-shared-styles/src/base/colors";

.c-select {
  margin-bottom: 15px;

  .input-validation-error {
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
  }

  .field-validation-error {
    padding-left: 20px;
  }

  .header & {
    margin: 0;
  }

  .c-select__option {
    &--is-focused:not(.c-select__option--is-selected) {
      background-color: transparent;
    }
  }

  &__label {
    display: flex;
    margin-bottom: 7px;
    margin-right: 10px;
    font-size: 13px;
    color: $form-element__label-color;
  }

  &__container {
    position: relative;
  }

  &__control {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: default;
    outline: 0 !important;
    height: 48px;

    .c-select_style_dropdown & {
      width: 100%;
      max-width: 350px;
      border: none;
      outline: none;
      box-shadow: none;
      background-color: transparent;

      &--is-focused {
        display: flex;
      }

      .c-select__single-value,
      .c-select__placeholder {
        font-weight: 700;
        color: map_get($color_blue, "primary-blue");
      }

      .c-select__dropdown-indicator {
        svg {
          fill: map_get($color_blue, "primary-blue");
        }
      }

      &:hover {
        .c-select__single-value {
          color: darken(map_get($color_blue, "primary-blue"), 8%);
        }

        .c-select__dropdown-indicator {
          svg {
            fill: darken(map_get($color_blue, "primary-blue"), 8%);
          }
        }
      }
    }

    &:hover {
      .c-select__indicator {
        svg {
          fill: $form-element__border-color_focus;
        }
      }
    }

    &:focus,
    &--is-focused {
      outline: none;
      color: $form-element__value-color;

      .c-select__indicator {
        svg {
          transform: scale(-1);
          fill: $form-element__border-color_focus;
        }
      }
    }

    &--is-disabled {

      .c-select__placeholder,
      .c-select__indicators {
        opacity: 0.5;
      }
    }
  }

  &__value-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    padding: 0 8px !important;

    &--has-value {
      color: $form-element__value-color;
    }

    .c-select__placeholder {
      color: $form-element__value-color;
    }

    // hack: fix for HCRALLY-1949 Member Portal - IE - blinking caret is displayed on member selector when changing members
    input[readonly] {
      text-indent: -9999em;
    }
  }

  &__indicators {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    align-self: stretch;
  }

  &__indicator-separator {
    display: none;
  }

  &__dropdown-indicator {
    svg {
      fill: $form-element__border-color_hover;
      transition-property: fill, transform;
      transition-duration: 0.12s;
      vertical-align: middle;
    }
  }

  .c-select__dropdown-indicator {
    padding: 0;
    padding-left: 8px;

    display: block; // important for IE
  }

  &__menu {
    min-width: 100%;
    margin: 10px 0;
    background-color: $color_white;
    box-shadow: none !important;
  }

  &__option {
    position: relative;
    background-color: $option__bg-color_default;
    color: $option__text-color_default !important;
    cursor: pointer;
    font-size: 14px;
    z-index: 2;

    &--is-selected {
      background-color: $option__bg-color_selected !important;
      color: $option__text-color_selected !important;
      font-weight: bold;
    }

    &:focus:not(:hover) {
      background-color: mix($form-element__border-color_focus,
          $color_white,
          10%);
      outline: none;
    }

    &:hover {
      font-weight: bold;

      &:not(.c-select__option--is-selected) {
        background-color: $option__bg-color_hover;
        color: $option__text-color_hover;
      }
    }
  }

  &_inline {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .c-select__label {
      margin-bottom: 0;
      color: $color_black;
      font-weight: bold;
      font-size: 14px;
    }
  }

  &_medium .c-select {
    font-size: 14px;

    &__label {
      max-height: 48px;
    }

    &__dropdown-indicator {
      margin-right: 7px;

      svg {
        @include size(16px);
      }
    }

    &__input {
      font-size: 14px;
    }

    &__option {
      padding: 15px 20px;
    }
  }

  &_large .c-select {
    &__control {
      height: 56px;
    }

    &__label {
      display: none;
    }

    &__value-container {
      padding: 19px 0 19px 30px;
    }

    &__dropdown-indicator {
      margin-right: 15px;

      svg {
        @include size(20px);
      }
    }

    &__input {
      font-size: 15px;
    }

    &__option {
      padding: 20px 30px;
    }
  }
}

.c-select-container {
  width: 100%;

  .c-select {
    &__control {
      background-color: $color_white;
      border: 1px solid $form-element__border-color;
      border-radius: 4px;
      color: $form-element__value-color;
      line-height: 1.35;
      transition-duration: 0.12s;
      will-change: border;

      &:hover:not(:focus) {
        border-color: $form-element__border-color_hover;
      }

      &:focus:hover,
      &--is-focused {
        border-color: $form-element__border-color_focus !important;
        box-shadow: none;
      }

      &--is-disabled {
        border-color: transparent;
        background-color: $form-element__bg-color_disabled;
      }
    }
  }
}

.c-select_style_dropdown {
  .c-select__menu {

    &::after,
    &::before {
      content: "";
      position: absolute;
      top: -10px;
      right: 0;
      left: 0;
      margin: 0 auto;
      width: 20px;
      height: 20px;
      transform: rotate(45deg);
      z-index: inherit;
    }

    &::before {
      background: $color_white;
      z-index: 2;
    }

    .c-select__menu-list {
      background-color: white;
      min-width: 200px;
    }

    .c-select__option {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .c-select__value-container {
    flex: none;
  }
}

.c-select {
  .c-select__single-value {
    max-width: none;
    position: static;
    transform: none;
    color: map_get($new-color_blue, "normal");
  }

  .c-select__single-value--is-disabled {
    color: map_get($new-color_blue, "middle");
  }
}

.c-select__menu-list {
  z-index: 18;
  padding: 1px 0;
  box-shadow: 0px -3px 20px 0 $color_black_shadow;
}