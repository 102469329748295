@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.MuiTooltip-tooltip.MuiTooltip-tooltip {
    color: map_get($new-color_white, "white");
    padding: 4px 8px;
    word-wrap: nowrap;
    border-radius: 4px;
    background: map_get($new-color_blue, "normal");
    @include new-caption;
}

.MuiTooltip-tooltipPlacementRight {
    margin: 0 8px !important;
}

.MuiTooltip-arrow.MuiTooltip-arrow {
    color: map_get($new-color_blue, "normal");
}

.MuiTooltip-arrow.MuiTooltip-arrow .MuiTooltip-arrow.MuiTooltip-arrow::before {
    background: map_get($new-color_blue, "normal");
}