@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.bullet-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 0;
    color: inherit;

    &__bullet {
        display: flex;
        border-radius: 5px;
        width: 8px;
        height: 8px;
        margin-right: 8px;
        min-width: 8px;

        &_green {
            background-color: map_get($new-color_green, 'normal');
        }

        &_blue {
            background-color: map_get($new-color_blue, "middle");
        }

        &_yellow {
            background-color: map_get($new-color_yellow, "bright");
        }

        &_orange {
            background-color: map_get($new-color_orange, "additional");
        }

        &_beet {
            background-color: map_get($new-color_beet, "pink");
        }
    }

    &__text {
        color: inherit;
    }
}