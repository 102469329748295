.facility-locator {
    &__map-legend {
        display: flex;
        flex-direction: row;

        &-item {
            padding: 24px 24px 24px 0;
        }
    }

    &__map-wrapper {
        margin: 0px -30px;
    }
}