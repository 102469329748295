@import "~@anytimefitness/hc-shared-styles/src/base/typography";
@import "~@anytimefitness/hc-shared-styles/src/base/colors";

.upload_dropzone {
    width: 100%;
}

.upload_container {
    display: flex;
    padding: 24px 0px !important;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border-radius: 16px;
    border: 1px dashed map_get($new-color_blue, "normal");
    text-align: center;
}

.upload_title {
    @include new-h3;
    font-weight: $bold_font-weight;
    color: map_get($new-color_blue, "normal");
    margin-bottom: 16px;
}

.upload_subtitle {
    @include new-subtitle2;
    font-weight: $regular_font-weight;
    color: map_get($new-color_grey, "dark");
    margin-top: 16px;
    white-space: pre-line;
}