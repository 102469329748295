@import "~@anytimefitness/hc-shared-styles/src/base/typography";
@import "~@anytimefitness/hc-shared-styles/src/base/colors";

.skip-link-container {
    .skip-link {
        position: absolute;
        top: -40px;
        left: 0;
        padding: 6px;
        font-weight: $black_font-weight;
        color: map_get($new-color_blue, "normal");
    }

    .skip-link:focus {
        position: static;
        left: 0;
        top: 0;
    }
}