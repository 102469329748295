@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.benefits-section {
    display:  flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    gap: 32px;
    @include md-down {
        gap: 16px;
    }

    & &__header {
        @include new-h2;
        color: map-get($new-color_blue, "normal");
        text-align: center;
        font-weight: $black_font-weight;
        padding: 0px 160px;
        @include md-down {
            text-align: left;
            padding: 0px 16px;
        }
    }

    & &__items {
        margin-top: 40px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        grid-column-gap: 32px;
        grid-row-gap: 72px;
        padding: 0 64px 0;
        @include md-only {
            grid-column-gap: 16px;
            grid-row-gap: 64px;
            padding: 0 32px 0;
        }
        @include md-down {
            grid-template-columns: none;
            grid-row-gap: 56px;
            padding: 0 16px 0;
        }
    }

    & &__item {
        display: flex;
        position: relative;
        flex-direction: column;
        background-color: map_get($new-color_blue, "light_10%");
    }

    & &__text {
        @include new-h3;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 56px 32px 44px;
        font-weight: $bold_font-weight;
        text-align: center;
        color: map-get($new-color_blue, "normal");
        @include lg-down {
            padding: 56px 32px 32px;
        }
    }

    & &__icon {
        display: flex;
        position: absolute;
        top: -40px;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        align-items: center;
        width: 80px;
        height: 80px;
        font-size: 80px;
        color: map-get($new-color_blue, "normal");
    }

    & &__subtitle {
        @include new-h3;
        color: map-get($new-color_grey, "dark");
        text-align: center;
        padding: 0px 224px;
        @include md-down {
            text-align: left;
            padding: 0px 16px;
        }

        @include md-only {
            padding: 0px 32px;
        }
    }
}