@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

//defaults
$default-space: 10px;
$default-mid-space: ceil($default-space / 1.5);
$default-large-space: $default-space * 1.5;
$default-border-radius: 4px;

$large_icon_size: 15px;
$mid_icon_size: 14px;

$slow: .4s;
$bezier: cubic-bezier(0.15, 0.9, 0.5, 1);
$classic-transition: all $slow $bezier;

//notifications
$notifications-success-bg-color: map_get($new_color_blue, "normal");
$notifications-success-hover-bg-color: map_get($new_color_blue, "normal");
$notifications-failure-bg-color: map_get($new-color_beet, "dark");
$notifications-failure-hover-bg-color: map_get($new-color_beet, "dark");
$notifications-warning-bg-color: $color_yellow;
$notifications-warning-hover-bg-color: $color_yellow;
$notifications-font-color: map_get($new-color_white, "white");
$notifications-clear-icon-size: $mid_icon_size;
$notifications-status-icon-size: $large_icon_size;
$notifications-z-index: 1000;

.notifications-container {
	z-index: $notifications-z-index;
	top: 8px;
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	user-select: none;
	width: max-content;

	.notification {
		display: flex;
		position: relative;
		padding: 16px 32px;
		transform: translateY(100%);
		border-radius: $default-border-radius;
		opacity: 0;
		transition: $classic-transition;
		transform-origin: bottom center;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 16px;

		&__success {
			background-color: $notifications-success-bg-color;

			.notification_status-icon {
				svg {
					fill: $notifications-success-bg-color;
				}
			}

			&:hover {
				background-color: $notifications-success-hover-bg-color;

				.notification_status-icon {
					svg {
						fill: $notifications-success-hover-bg-color;
					}
				}
			}
		}

		&__error {
			background-color: $notifications-failure-bg-color;

			.notification_status-icon {
				svg {
					fill: $notifications-failure-bg-color;
				}
			}

			&:hover {
				background-color: $notifications-failure-hover-bg-color;

				.notification_status-icon {
					svg {
						fill: $notifications-failure-hover-bg-color;
					}
				}
			}
		}

		&__warning {
			background-color: $notifications-warning-bg-color;

			.notification_status-icon {
				svg {
					fill: $notifications-warning-bg-color;
				}
			}

			&:hover {
				background-color: $notifications-warning-hover-bg-color;

				.notification_status-icon {
					svg {
						fill: $notifications-warning-hover-bg-color;
					}
				}
			}
		}

		&__appeared {
			transform: translateY(0);
			opacity: 1;
		}

		.notification_status-icon {
			color: $color_white;

			svg {
				width: $notifications-status-icon-size;
				height: $notifications-status-icon-size;
			}
		}

		.notification_message {
			vertical-align: middle;
			color: $notifications-font-color;
			@include new-body1;
		}

		.notification_clear-icon {
			cursor: pointer;

			svg {
				fill: $color_white;
				width: $notifications-clear-icon-size;
				height: $notifications-clear-icon-size;
			}
		}
	}
}