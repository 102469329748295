@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.solutions-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;

    & &__items {
        margin-top: 80px;
        display: grid;
        grid-column-gap: 32px;
        grid-row-gap: 56px;
        

        @include lg-up{
            grid-auto-flow: column;
            grid-row-gap: 0px;
        }

        @include md-only{
            margin-top: 64px;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 24px;
        }

        @include md-down{
            margin-top: 32px;
            grid-template-columns: repeat(1, 1fr);
        }
    }

    & &__item {
        display: flex;
        flex-direction: column;
    }

    & &__item-icon {
        font-size: 8rem;
        color: map_get($new-color_blue, "normal");

        @include md-down{
            font-size: 6.667rem;
        }
    }

    & &__item-header {
        @include new-h3;
        color: map_get($new-color_blue, "normal");
        font-weight: $bold_font-weight;
        margin-top: 52px;

        @include md-down{
            margin-top: 26px;
        }
    }

    & &__item-description {
        @include new-body1;
        color: map_get($new-color_grey, "dark");
        margin-top: 24px;

        @include md-down{
            margin-top: 16px;
        }
    }

    & &__link-spacer {
        margin-bottom: 48px;

        @include md-down{
            margin-bottom: 16px;
        }
    }

    & &__link-container {
        margin-top: auto;
    }

    & &__link {
        @include new-subtitle1;
        color: map_get($new-color_blue, "normal");
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: auto;

        &:hover, &:hover span {
            color: map_get($new-color_blue, "normal");
        }
    }

    & &__link-icon {
        margin-left: 16px;
        color: map_get($new-color_blue, "normal");
    }
}