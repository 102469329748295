@import "~@anytimefitness/hc-shared-styles/src/utils/mixins";
@import "~@anytimefitness/hc-shared-styles/src/base/colors";

.card-info {
  $spacing: 30px;

  padding: 0 $spacing $spacing $spacing;
  margin-bottom: ($spacing / 1.5);
  background-color: $color_white;
  position: relative;

  &--half {
    padding: 0 ($spacing/1.875) ($spacing/1.875) ($spacing/1.875);
    margin: ($spacing / 1.25) 0;
  }

  &--shadow {
    box-shadow: 2px 2px 15px 0 rgba($color_black, 0.1);
    transition: box-shadow 0.15s ease-in;
    will-change: box-shadow;
  }

  &--no-padding {
    padding: 0 !important;
  }

  &--no-inner-margin {
    margin: 0 !important;
  }

  &__title {
    padding-top: $spacing;
  }

  &__box {
    margin-top: $spacing;
    height: calc(100% - #{$spacing});
  }

  &__subtitle {
    margin-top: ($spacing / 6);
    color: map_get($color_grey, "dusty");
  }

  &__content {
    &>div[class*="col-"] {
      padding: 15px;
    }
  }
}

.new-card-info {
  $spacing: 30px;

  padding: 0 $spacing $spacing $spacing;
  margin-bottom: ($spacing / 1.5);
  background-color: $color_white;
  position: relative;

  &__title {
    padding-top: $spacing;
  }

  &__box {
    margin-top: $spacing;
    height: calc(100% - #{$spacing});
  }

  &__subtitle {
    margin-top: ($spacing / 6);
    color: map_get($color_grey, "dusty");
  }

  &__content {
    &>div[class*="col-"] {
      padding: 15px;
    }
  }
}