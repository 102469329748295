.partner-group-membership-payments-container {
	margin-top: 30px;

	&__filters {
        margin-top: 16px;
    }

	&__excel-button {
        float: right;
    }
}
