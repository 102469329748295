@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";
@import "~@anytimefitness/hc-shared-styles/src/utils/media";
@import "~@anytimefitness/hc-shared-styles/src/layout/footer";


.footer-internal {
    @extend .footer;

    @include lg-up {
        display: flex;
    }

    .footer__privacy-container {
        width: auto;
        display: flex;
        align-items: flex-end !important;
        justify-content: center;
        text-align: left;
        padding: 16px 32px !important;

    }

    .footer__cookie-container {
        text-align: left;
        float: left;
    }

    .footer__menu-container {
        padding: 16px 0 !important;

        @include lg-down {
            float: left;
            display: flex;
        }
    }

    .footer__menu-list {
        height: auto !important;
        justify-content: center !important;
        padding: 0 !important;

        li {
            display: flex;
            padding: 0 16px;

            &.separator {
                border-right: 1px solid map_get($new-color_white, "white");
                padding: 0 5px !important;
            }

            @include lg-up {
                text-align: center;
                align-items: center;
                justify-content: center;
            }
        }

        li+li {
            @include lg-down {
                margin-top: 16px;
            }
        }

        &:has([class*="separator"]) {
            flex-direction: row !important;
        }

        @include lg-down {
            justify-content: space-between;
        }
    }

    .footer-menu-item__submenu-link {
        @include new-subtitle2;
        padding: 0 16px;
        text-decoration: none;

        @include lg-down {
            display: block !important;
            visibility: visible !important;
        }
    }

    .footer__allyant-container {
        justify-content: center !important;
        text-align: right;

        .footer__img {
            height: 40px;
        }

        @include lg-down {
            justify-content: flex-end !important;
            align-items: center !important;
            padding: 16px 32px;
            position: absolute;
            float: right;
            bottom: 0;
            right: 0;
        }

        @include md-down {
            position: relative;
        }
    }
}

@include lg-down {
    .show-only-desktop {
        display: none !important;
    }
}

@include lg-up {
    .not-show-desktop {
        display: none !important;
    }
}