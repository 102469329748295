@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.legal-contract {
    &__title-container {
        display: flex;
        margin-top: 24px;
        gap: 10px;

        &_back-button {
            font-weight: $regular_font-weight;
            position: inherit;
            margin-top: 1px;
            font-size: 24px;
            cursor: pointer;
            color: map_get($new-color_blue, "normal");
        }

        &_title {
            @include new-h3;
            font-weight: $bold_font-weight;
            color: map_get($new-color_blue, "normal");
        }
    }

    &__subtitle {
        margin: 8px 0 16px;
    }

    &__box-icon {
        color: map_get($new-color_blue, "normal");
    }

    .equipment-and-facility-policies {
        &__container {
            height: 400px;
            width: 100% !important;
            overflow-y: scroll;
            border: 1px solid $form-element__border-color;
            padding: 15px;
            margin-bottom: 15px;

            .underlined {
                text-decoration: underline;
            }

            .email {
                text-decoration: none !important;
            }
        }
    }
}