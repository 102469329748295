@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.nominate {
    display: flex;
    flex-direction: column;
    padding-top: 64px;
    padding-bottom: 124px;

    @include md-only {
        padding-top: 48px;
        padding-bottom: 104px;
    }

    @include md-down {
        padding-top: 32px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 88px;
    }

    &__row {
        display: flex;
        flex-direction: row;
        gap: 32px;

        @include md-down {
            flex-direction: column;
            gap: inherit;
        }
    }

    &__row-field {
        width: 50%;

        @include md-down {
            width: 100%;
        }

        .MuiSelect-icon {
            top: 20px;
        }
    }

    &__text-fields {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__step-info-text {
        @include new-body1;
        font-weight: $regular_font-weight;
        color: map-get($new-color_grey, "dark");
        margin-top: 8px;
        margin-bottom: 32px;
    }

    &__section--buttons {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 16px;
        width: 100%;
    }

    &__title-text {
        @include new-h2;
        color: map_get($new-color_blue, "normal");
        font-weight: $black_font-weight;
        text-align: center
    }

    &__description {
        @include new-h3;
        font-weight: $regular_font-weight;
        color: map-get($new-color_grey, "dark");
        text-align: center;
        margin-top: 24px;

        @include md-down {
            margin-top: 16px;
        }
    }

    &__info {
        @include new-body1;
        font-weight: $regular_font-weight;
        color: map-get($new-color_grey, "dark");
        text-align: center;
        padding-left: 280px;
        padding-right: 280px;
        margin: 24px auto 48px;

        @include md-only {
            padding-left: 64px;
            padding-right: 64px;
        }

        @include md-down {
            padding-left: 16px;
            padding-right: 16px;
            margin: 16px auto 32px;
        }
    }

    &__captcha {
        margin-bottom: 40px;
    }

    &__main-section {
        margin: auto;
        width: auto;

        @include md-up {
            width: 640px;
        }
    }
}