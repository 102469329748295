@import "~@anytimefitness/hc-shared-styles/src/base/colors";

.radio-group {
  &__label {
    display: block;
    margin-bottom: 15px;
    color: $form-element__label-color;
  }

  &__content {
    display: flex;
    flex-direction: column;

    &_inline {
      flex-direction: row;

      .c-radio {
        margin-right: 30px;
      }
    }
  }
}