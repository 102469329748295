.voucher-program {
	.layout__title {
		padding-left: 28px;
		margin-bottom: 10px;
	}
	.card-info .info-box {
		margin-top: 0;
	}

	.info-box__content {
		margin-bottom: 24px;
	}
}