@import "~@anytimefitness/hc-shared-styles/src/components/info-box";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.prtl-table {
    .info-box__table-container {
        display: block;
        max-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        margin-top: 30px;

        .info-box__table {
            width: 100%;
        }
    }

    .info-box__table-cell_center-text {
        text-align: center;
    }

    .info-box__table-controls-container {
        .info-box__table-size-selector-container {
            .c-select__control {
                min-width: 71px;
            }
        }

        .table-pager {
            &__seek-first {
                background-image: url(../../assets/icons/ic-action-double-arrow-left.svg);

                &:hover {
                    background-image: url(../../assets/icons/ic-action-double-arrow-left-hover.svg);
                }

                &:disabled {
                    background-image: url(../../assets/icons/ic-action-double-arrow-left-disabled.svg);
                }
            }

            &__seek-prev {
                background-image: url(../../assets/icons/ic-action-arrow-left.svg);

                &:hover {
                    background-image: url(../../assets/icons/ic-action-arrow-left-hover.svg);
                }

                &:disabled {
                    background-image: url(../../assets/icons/ic-action-arrow-left-disabled.svg);
                }
            }

            &__seek-next {
                background-image: url(../../assets/icons/ic-action-arrow-right.svg);

                &:hover {
                    background-image: url(../../assets/icons/ic-action-arrow-right-hover.svg);
                }

                &:disabled {
                    background-image: url(../../assets/icons/ic-action-arrow-right-disabled.svg);
                }
            }

            &__seek-last {
                background-image: url(../../assets/icons/ic-action-double-arrow-right.svg);

                &:hover {
                    background-image: url(../../assets/icons/ic-action-double-arrow-right-hover.svg);
                }

                &:disabled {
                    background-image: url(../../assets/icons/ic-action-double-arrow-right-disabled.svg);
                }
            }
        }
    }
}

.mui-info-box {
    min-width: 288px;
    padding: 0 !important;
    border-radius: 4px !important;
    gap: 16px;
    background: linear-gradient(0deg, map_get($new-color_blue, "lighter"), map_get($new-color_blue, "lighter")),
        linear-gradient(0deg, map_get($new-color_blue, "light_10%"), map_get($new-color_blue, "light_10%"));


    &__edit-icon {
        position: absolute;
        right: 50px;
        top: 13px;
    }

    &__title {
        @include new-subtitle1;
        font-weight: $bold_font-weight;
        text-align: left;
        position: relative;
        color: map_get($new-color_blue, "normal");
    }

    &__content {
        @extend .info-box__content;
        padding: 0 !important;
    }
}

.mui-info-box+.mui-info-box {
    margin-top: 16px;
}

.mui-info-box_bordered {
    border: 1px solid map_get($new-color_blue, "light") !important;
    box-shadow: none !important;
}

.material-icon {
    color: map_get($new-color_blue, "middle");
}

.Mui-expanded {

    .mui-info-box__edit-icon {
        top: 20px
    }
}

.MuiAccordionSummary-content,
.Mui-expanded .MuiAccordionSummary-content {
    padding: 16px 0 !important;
    margin: 0 !important;
}

.MuiCollapse-root {
    padding: 0 16px 16px;
}