@import "~@anytimefitness/hc-shared-styles/src/base/colors";

.infinite-scroll-component {
  overflow: inherit !important;
}

.new-info-box__table {
  width: 100%;

  &-cell_head_sticky-thead {
    position: -webkit-sticky;
    position: sticky;
    z-index: 5;
  }

  &-cell_sticky {
    position: -webkit-sticky;
    position: sticky;
    z-index: 4;
  }

  thead {
    width: 100%;
    border-radius: 4px 4px 0px 0px;
    background: map_get($new-color_blue, "lighter");
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 5;

    tr {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 5;
      background: map_get($new-color_blue, "lighter");
    }
  }
}