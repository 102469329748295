@import "~@anytimefitness/hc-shared-styles/src/utils/media";
@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.reimbursement {
    &__title {
        @include new-h3;
        font-weight: $bold_font-weight;
        color: map_get($new-color_blue, "normal");
        margin-bottom: 16px;
    }

    &__button {
        padding: 0 !important;
    }

    &__group_container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 24px;
        position: relative;
    }
}