@import "~@anytimefitness/hc-shared-styles/src/utils/media";

.sections_divider {
    margin-top: 128px;
    @include md-only {
        margin-top: 104px;
    }
    @include md-down {
        margin-top: 88px;
    }
}