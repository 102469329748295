@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.input_readOnly {

	background-color: transparent !important;
	padding: 0;
	cursor: text;

	&::before,
	&::after {
		border-bottom: 1px solid map_get($new-color_blue, "light");
		border-bottom-style: solid !important;
	}

	&:hover {
		border-color: map_get($new-color_blue, "normal") !important;

		&::before {
			border-bottom: 1px solid map_get($new-color_blue, "light");
			border-bottom-style: solid !important;
		}
	}

	input {
		padding: 14px 16px 14px 0;
	}
}

.label_readOnly {
	transform: translate(0px, -12px) scale(1) !important;
}

.inputAdornment_readOnly {
	display: none !important;
}

.inputAdornment {
	p {
		color: map_get($new-color_blue, "normal");
		@include new-body1;
	}
}

.select_input_disabled {
	color: map_get($new-color_blue, "middle");
	background-color: map_get($new-color_blue, "light_30%");
}

.select_icon_disabled {
	fill: map_get($new-color_blue, "middle");
}

.paper {
	max-height: 208px;
}

.elevation {
	border: none;
	box-shadow: 0px 6px 10px 0px map_get($new-color_grey, "light_14%"),
		0px 1px 18px 0px map_get($new-color_grey, "light_14%"),
		0px 3px 5px 0px map_get($new-color_grey, "light_14%");
}