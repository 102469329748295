@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.bullet-caption {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 0;

    &__bullet {
        display: flex;
        border-radius: 5px;
        width: 8px;
        height: 8px;
        margin-right: 8px;
        min-width: 8px;

        &_green {
            background-color: map_get($new-color_green, 'normal');
        }

        &_blue {
            background-color: map_get($new-color_blue, "normal");
        }

        &_beet {
            background-color: map_get($new-color_beet, "bright");
        }

        &_yellow {
            background-color: map_get($new-color_yellow, "bright");
        }
    }

    &__text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &_grey {
            color: map_get($new-color_grey, "dark");
        }

        &_blue {
            color: map_get($new-color_blue, "normal");
        }

        &_big {
            @include new-subtitle1;
        }

        &_small {
            @include new-body1
        }

        &_medium {
            @include new-overline
        }
    }
}