 @import "~@anytimefitness/hc-shared-styles/src/base/colors";

 .checkbox-group {
 	&__label {
 		display: block;
 		margin-bottom: 15px;
 		color: $form-element__label-color;
 	}

 	&__content {
 		display: flex;
 		flex-direction: column;

 		&_inline {
 			flex-direction: row;

 			.c-checkbox {
 				margin-right: 30px;
 			}
 		}
 	}
 }