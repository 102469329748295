@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.call-to-action {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    align-self: stretch;
    margin: 0 -64px;

    @include md-only {
        margin: 0 -32px;
    }

    @include md-down {
        margin: 0 -16px;
    }

    &--left-image {
        flex-direction: row-reverse;
    }

    &--right-image {
        flex-direction: row;
    }

    &--without-image {
        margin: 0;
    }

    &__content-container {
        display: grid;
        align-items: flex-start;
        text-align: left;
        grid-row-gap: 16px;

        &--columns-1 {
            width: 50%;
            grid-template-columns: 1fr;
            padding: 104px 64px 32px;

            @include md-only {
                padding: 104px 32px 32px;
            }

            @include md-down {
                width: 100%;
                padding: 8px 16px 0 16px;
            }
        }

        &--columns-2 {
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 24px;

            @include md-down {
                grid-template-columns: 1fr;
                grid-row-gap: 16px;
            }
        }
    }

    &__subtitle {
        @include new-h3;
        font-weight: $black_font-weight;
        color: map_get($new-color_blue, "normal");
    }

    &__title {
        color: map_get($new-color_blue, "normal");
        font-weight: $black_font-weight !important;

        &--columns-1 {
            @include new-h2;
        }

        &--columns-2 {
            @include new-h3;
        }
    }

    &__description {
        color: map_get($new-color_blue, "normal");
        padding-bottom: 16px;
        white-space: pre-wrap;

        @include md-down {
            padding: 0;
        }

        &--columns-1 {
            @include new-h4;
        }

        &--columns-2 {
            @include new-h3;
        }
    }

    &__button-container {
        padding-bottom: 16px;

        @include md-down {
            width: 100%;
            padding: 0;
        }
    }

    &__image-container {
        display: flex;
        width: 50%;
        height: auto;
        overflow: hidden;
        justify-content: center;
        border-bottom-left-radius: 64px;
        align-self: stretch;

        @include md-down {
            width: 100%;
            border-bottom-left-radius: 0;
            max-height: 200px;
        }
    }

    &__image {
        height: 100%;
        width: 100%;
        object-fit: cover;

        @include md-down {
            height: auto;
        }
    }

    &__image--fitness-hr {
        @include md-down {
            object-position: top;
        }
    }

    &__image--man-wprosthetic-workout {
        @include md-down {
            object-position: top;
        }
    }

    @include md-down {
        flex-direction: column-reverse;
    }

    &__description-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}