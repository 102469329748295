@import "../styles/variables";
@import "~@anytimefitness/hc-shared-styles/src/utils/media";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";
@import "~@anytimefitness/hc-shared-styles/src/base/colors";

.layout {
  display: flex;
  flex: 1;

  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
    background: #fff;
    width: 100%;
    max-width: 1792px;
    margin: 0 auto;

    .route-wrapper {
      flex: 1 0 auto;
    }

    &--with-sidebar {
      padding: $layout-content-inner-padding $layout-content-inner-padding-mobile $layout-content-bottom-padding $layout-content-inner-padding-mobile;
      overflow: auto;

      @include md-up {
        padding: $layout-content-inner-padding $layout-content-inner-padding-tablet $layout-content-bottom-padding $layout-content-inner-padding-tablet;
      }

      @include lg-up {
        padding: $layout-content-inner-padding $layout-content-inner-padding-desktop $layout-content-bottom-padding $layout-content-inner-padding-desktop;
      }
    }
  }

  &__title {
    @include new-h2;
    margin-bottom: 20px;
    font-weight: $black_font-weight;
    color: map_get($new-color_blue, "normal");
  }

  &__new-container {
    max-width: 1920px;
    margin: 0 auto 128px;

    @include lg-down {
      margin-bottom: 104px;
    }

    @include md-down {
      margin-bottom: 88px;
    }
  }

  &__new-title {
    margin-bottom: 24px;
    margin-top: 18px;
    @include new-h2;
    color: map-get($new-color_blue, "normal");
    font-weight: $black_font-weight;

    @include md-down {
      margin-bottom: 16px;
    }
  }
}

@include lg-down {
  .layout__new-container {
    margin-bottom: 104px;
  }
}

@include md-down {
  .layout__new-container {
    margin-bottom: 88px;
  }

  .layout__new-title {
    margin-bottom: 16px;
  }
}