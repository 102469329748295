@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.icon-caption {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    word-break: break-all;
    @include new-subtitle1;

    &__text {
        margin-left: 8px;
        color: map_get($new-color_grey, "dark");
    }
}