.voucher-program-form {
	&__file-name-container {
		display: flex;
		font-size: 13px;
		gap: 8px;
		padding-left: 24px;
		margin-bottom: 13px;

		p {
			margin: 0;
			font-size: 13px;
		}
	}
}