@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.react-confirm-alert {
	width: 560px;

	@include md-down {
		width: 100%;
		height: 100%;
	}

	& .custom-popup {
		@include md-down {
			border-radius: 0%;
			height: 100%;
		}
	}
}

.login {
	display: flex;
	flex-direction: column;
	gap: 32px;
	border-radius: 8px;
	margin-top: 40px;

	&__text-fields-wrapper {
		width: 100%;
	}

	&__text-fields {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	&__forgot-link {
		@include new-body1;
		text-align: right;
		text-decoration-line: underline;
		color: map_get($new-color_blue, "normal");
		background-color: transparent;
		border: none;
		padding: 0;
		cursor: pointer;
		padding-top: 30px;

		@include md-down {
			padding-top: 0px;
		}

		&:hover {
			color: map_get($new-color_blue, "normal");
		}
	}

	&__section {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		gap: 24px;
		width: 100%;
		justify-content: space-between;
		margin-top: 8px;

		@include md-down {
			flex-direction: column;
		}
	}

	&__signup-section {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	&__signup-section-text {
		@include new-body1;
		color: map_get($new-color_grey, "dark");
		height: 24px;
	}

	&__divider {
		margin: 0;
	}

	&__signup-buttons-wrapper {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		gap: 16px;

		@include md-down {
			flex-direction: column;
		}
	}

	&__signup-button {
		flex-grow: 1;
	}

	&__main-section {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
		width: 100%;
	}
}