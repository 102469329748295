.excel-image-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    min-height: 48px;
}

.excel-image {
    position: absolute;
    bottom: 0;
    right: 0;
}