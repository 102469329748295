@import "~@anytimefitness/hc-shared-styles/src/index";

html {
  overflow-y: scroll;
}

#ot-pc-desc {
  font-size: 1.5em !important
}

.ot-cat-header {
  font-size: 1.5em !important
}

#ot-category-title {
  font-size: 1.5em !important
}

.ot-category-desc {
  font-size: 1.4em !important
}

.ot-always-active {
  font-size: 1.4em !important
}

#accept-recommended-btn-handler {
  font-size: 1.4em !important
}

.ot-pc-refuse-all-handler {
  font-size: 1.4em !important
}

.save-preference-btn-handler {
  font-size: 1.4em !important
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.height-auto {
  height: auto !important;
}

.no-margin {
  margin: 0 !important;
}

.no-wrap {
  white-space: nowrap;

}

.action-buttons {
  padding: 0 16px;
  text-wrap: nowrap;

  .btn {
    margin: 0 16px 16px 0;
  }
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.text-center {
  text-align: center;
}

.margin-auto {
  margin: auto;
}

.hidden-on-mobile {
  @include md-down {
    display: none;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ul-no-style {
  list-style: none;
  padding: 0;
  margin: 0;
}

.slick-active button {
  background: map_get($new-color_blue, "normal") !important;
}
