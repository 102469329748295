@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";


.icon-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    word-break: break-all;

    &--notext {
        padding: 0;

        &:hover {
            text-decoration: none !important;
        }
    }

    &__icon {
        display: flex;
    }

    &__text {
        @include new-subtitle1;
        font-weight: $bold_font-weight;
        color: map_get($new-color_grey, "dark");
    }
}