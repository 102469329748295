@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";
@import "~@anytimefitness/hc-shared-styles/src/utils/utils";

.facility-locator {
    &--hidden-on-mobile {
        @include md-down {
            display: none !important;
        }
    }

    &__map {
        position: relative;
        height: 70vh;
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        @include md-up {
            border-top: 1px solid map_get($new-color_blue, "light");
            flex-direction: row;
        }
    }

    &__map-viewport {
        position: relative;
        height: 100%;
        width: 100%;
        margin: 0;
        display: flex;
        visibility: visible;

        &--hidden-on-mobile {
            @include md-down {
                visibility: hidden;
                position: absolute;
            }
        }
    }

    &__box-icon {
        color: map_get($new-color_blue, "middle");
    }

    &__back-icon {
        color: map_get($new-color_blue, "normal");
        font-size: 24px !important;
    }

    &__back-icon-button {
        color: map_get($new-color_blue, "normal");
        @include new-subtitle1;
        font-weight: $bold_font-weight;
    }

    &__chevron-icon {
        color: map_get($new-color_blue, "middle");
    }

    &__chevron-icon-button {
        color: map_get($new-color_blue, "middle");
        @include new-caption;
    }

    // neccessary for pin tip to point exactly at location,
    // by default react-google map position marker's top left corner
    &__pin-container {
        width: 72px;
        margin-left: -33px;
        margin-top: -56px;
        cursor: pointer;

        &_blue {
            path {
                fill: map_get($new-color_blue, "normal");
            }

            &-active path {
                fill: map_get($new-color_blue, "normal");
            }

            &:hover path {
                fill: map_get($new-color_blue, "darker_10%");
            }

            &-active:hover path {
                fill: map_get($new-color_blue, "darker_10%");
            }
        }

        &_green {
            path {
                fill: map_get($new-color_blue, "normal");
            }

            &-active path {
                fill: map_get($new-color_blue, "normal");
            }

            &:hover path {
                fill: map_get($new-color_blue, "darker_10%");
            }

            &-active:hover path {
                fill: map_get($new-color_blue, "darker_10%");
            }
        }

        &_beet {
            path {
                fill: map_get($new-color_beet, "bright");
            }

            &-active path {
                fill: map_get($new-color_beet, "bright");
            }

            &:hover path {
                fill: map_get($new-color_beet, "dark");
            }

            &-active:hover path {
                fill: map_get($new-color_beet, "dark");
            }
        }
    }

    &__reset-current-location {
        position: absolute;
        z-index: 1;
        margin: 10px;
        padding: 7px;
        right: 0;
        bottom: 105px;
        width: 40px;
        height: 40px;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
        background-color: white;
        border: 1px solid white;
        border-radius: 2px;
        cursor: pointer;

        .material-icon {
            color: #666;
        }

        &:hover {
            .material-icon {
                color: #333;
            }
        }

        &:active {
            .material-icon {
                color: #111;
            }
        }
    }

    &__facility-info-popup {
        width: 326px;
        border-radius: 8px;
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
        padding: 16px;
        background-color: white;
        z-index: 100;
        position: relative;
        margin-left: -163px;
        margin-top: -33px;
    }

    &__facility-info-divider {
        width: auto;
        margin: 0px;
        border-top: 1px solid map_get($new-color_blue, "light");
    }

    &__facilities-panel-wrapper {
        position: relative;

        @include md-up {
            max-width: 432px;
        }

        &_toggle-btn {
            display: flex;
            position: absolute;
            top: 50%;
            right: -20px;
            z-index: 1;
            padding: 16px 0;
            background: white;
            border-width: 1px 1px 1px 0px;
            border-style: solid;
            border-color: #BDCBD5;
            border-radius: 0px 4px 4px 0px;
            width: fit-content;

            @include md-down {
                display: none;
            }
        }

        &--hidden {
            @include md-up {
                display: none !important;
            }
        }

        &--overflow-on-sm-only {
            @include md-down {
                overflow-y: auto;
            }
        }

        &_tooltip-wrapper {
            position: relative;
        }
    }

    &__facilities-panel {
        display: flex;
        position: relative;
        flex-direction: column;
        z-index: 1;
        width: 100%;
        background-color: $color_white;
        height: 100%;
        align-items: center;
        padding-top: 24px;
        gap: 8px;
        border-left: 1px solid map_get($new-color_green, "light");

        &.list-view {
            @include md-down {
                height: 100vh;
            }
        }

        .facility-locator__map-legend-wrapper {
            padding-top: 10px;
            display: flex;
            width: 100%;

            @include md-up {
                display: none;
            }

            .facility-locator__map-legend {
                display: flex;
                flex-direction: row;
                align-items: center;
                grid-gap: 24px;
                gap: 24px;
                padding: 16px 7px 16px 0px;
                width: 100%;
                background-color: rgba(189, 203, 213, 0.1);
                border-width: 1px 0px;
                border-style: solid;
                border-color: map_get($new-color_blue, "light");
                white-space: nowrap;

                @include md-down {
                    gap: 8px;
                }

                .facility-locator__map-legend-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0px;
                    grid-gap: 8px;
                    gap: 8px;

                    &:first-child {
                        margin-left: 32px;
                    }

                    &:last-child {
                        min-width: 0;
                    }
                }
            }
        }
    }

    &__facilities-panel-subtitle {
        @include new-caption;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 0px 16px;
        gap: 8px;
        width: 100%;

        @include lg-up {
            padding: 0;
        }
    }

    &__facilities-panel-item {
        padding: 7px 24px;
        margin: 0 24px;
        cursor: pointer;
        border-top: 1px solid map_get($new-color_blue, "light");
        ;

        &:hover {
            background-color: map_get($new-color_blue, "light");
        }
    }

    &__facilities-panel-item-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__facilities-panel-item-name {
        @include new-body1;
        font-weight: $bold_font-weight;
    }

    &__facilities-panel-no-clubs {
        @include new-body1;
    }

    &__facilities-panel-item-text-line {
        @include new-subtitle1;
        color: map_get($new-color_grey, "dark");
        padding-left: 16px;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__facilities-panel-item-distnace {
        @include new-caption;
        flex-shrink: 0;
    }

    &__search-box-underlay {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px 24px;
        gap: 16px;
        width: 100%;
    }

    &__facilities-panel-list {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        width: 100%;
        display: block;

        &--hidden-on-mobile {
            @include md-down {
                display: none !important;
            }
        }
    }

    &__facility-info-search-divider {
        margin: 0;
    }

    &__facility-detailed-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 16px 24px 24px;
        gap: 16px;
    }

    &__facility-detailed-info-wrapper {
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        display: block;
    }

    &__facility-detailed-program-divider {
        font-weight: $bold_font-weight;
        font-size: 24px;
        line-height: 24px;
        margin: 0 5px;
    }

    &__facility-detailed-title-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 100%
    }

    &__facility-detailed-title {
        @include new-h4;
        color: map_get($new-color_blue, "normal");
        font-weight: $bold_font-weight;
    }

    &__facility-detailed-program {
        @include new-subtitle1;
        color: map_get($new-color_blue, "normal");
        font-weight: $bold_font-weight;
    }

    &__facility-detailed-programs {
        @include new-subtitle1;
        color: map_get($new-color_grey, "dark");
    }
}

// override Google Places Autocomplete default styles
.pac-container {
    width: 382px !important; // should be equal to search input + 2 icons in the searchbox
    margin: 13px -49px !important;
    border-radius: 4px;
    box-shadow: none;
    border: 1px solid map_get($new-color_blue, "light");
    border-width: 0px 1px 1px 1px;

    .pac-icon {
        background-image: url(../../../../../assets/icons/place.svg);
        background-size: cover;
    }

    .pac-icon-marker {
        background-position: unset;
    }

    .pac-item {
        padding: 8px;
        line-height: 24px;

        .pac-matched {
            color: map_get($new-color_blue, "normal") !important;
        }

        span {
            @include new-body1;
            color: map_get($new-color_blue, "light");

        }
    }

    .pac-item:hover span {
        color: map_get($new-color_blue, "middle");
    }

    &:after {
        /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */
        background-image: none !important;
        height: 0px;
    }
}

.cluster-marker {
    color: #fff;
    background: map_get($new-color_blue, "normal");
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    @include new-caption;
    font-weight: $bold_font-weight;

    &:hover {
        background: map_get($new-color_blue, "darker_10%");
    }
}

::-webkit-input-placeholder {
    /* Chrome */
    color: map_get($new-color_blue, "middle");
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: map_get($new-color_blue, "middle");
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: map_get($new-color_blue, "middle");
    opacity: 1;
}

:-moz-placeholder {
    /* Firefox 4 - 18 */
    color: map_get($new-color_blue, "middle");
    opacity: 1;
}