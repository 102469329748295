@import "~@anytimefitness/hc-shared-styles/src/base/typography";
@import "~@anytimefitness/hc-shared-styles/src/base/colors";

.partner-billing-info {
    max-width: 800px;

    .partner-billing-info {
        &__banking-account-details {
            display: flex;
            padding-top: 4px;
            padding-bottom: 4px;
            gap: 8px;
            flex-direction: column;
        }

        &__banking-account-details-row {
            line-height: normal;
        }

        &__banking-account-details-label {
            @include new-caption;
            color: map_get($new-color_blue, "middle");
            margin-right: 4px;
        }

        &__banking-account-details-value {
            @include new-caption;
            color: map_get($new-color_blue, "normal");
        }

        &__retrieve-button {
            margin-top: 32px;
            width: 100%;
        }
    }
}