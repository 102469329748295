@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.react-confirm-alert-body {
	width: auto;
}

.react-confirm-alert-overlay {
	background: map_get($new-color_blue, "light_50%");
}

.custom-popup {
	display: flex;
	flex-direction: column;
	padding: 24px 32px 48px;
	background: map_get($new-color_white, "white");
	border-radius: 8px;

	@include md-down {
		padding: 24px 24px 82px;
	}

	&__close-icon-button {
		flex-direction: row;
		justify-content: flex-end;
		gap: 10px;
		width: 100%;
		margin-bottom: 8px;
		background-color: transparent;
		border: none;
		color: map_get($new-color_blue, "normal");
		display: inline-flex;
		cursor: pointer;
		padding: 0;
		margin-left: auto;

		&:hover * {
			color: map_get($new-color_blue, "normal");
		}

		@include md-down {
			margin-bottom: 88px;
		}

	}

	&__title-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;

		@include md-down {
			gap: 8px;
		}
	}

	&__title {
		display: flex;
		flex-direction: column;
		gap: 16px;
		width: 100%;
		@include new-h3;
		font-weight: $bold_font-weight;
		color: map_get($new-color_blue, "normal");
	}

	&__subtitle {
		display: flex;
		flex-direction: column;
		width: 100%;
		@include new-body1;
		color: map_get($new-color_grey, "dark");
		max-width: 385px;

		@include md-down {
			max-width: 312px;
		}
	}
}