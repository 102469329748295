@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.member-transfer {
    &__fields-wrapper {
        margin-top: 16px;

        &__fields {
            height: 500px;

            &__info {
                @include new-body1;
            }
        }

        &__actions {
            bottom: 0;
        }

        .facility-search-box {
            margin-top: 24px;
        }

        .warning-box {
            margin-top: 24px;
        }
    }
}

.members-transfer-result {
    &__title {
        @include new-h2;
        font-weight: $black_font-weight;
        color: map_get($new-color_blue, "normal");
        margin-bottom: 20px;
    }

    .MuiButton-label {
        color: map_get($new-color_blue, "normal");
    }

    .material-icons-outlined {
        color: map_get($new-color_blue, "normal");
    }

    .MuiButton-outlined {
        border: 3px solid map_get($new-color_green, 'normal');
        font-weight: $black_font-weight;
    }
}