@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.data-privacy-request {
    padding: 64px 0 128px;

    @include md-only {
        padding: 48px 0 104px;
    }

    @include md-down {
        padding: 32px 16px 88px;
    }

    &__title {
        @include new-h2;

        font-weight: $black_font-weight;
        color: map_get($new-color_blue, "normal");
        text-align: center;
        margin-bottom: 48px;

        @include md-down {
            margin-bottom: 32px;
        }
    }

    &__frame {
        max-width: 608px;
        height: 800px;
        margin: 0 auto;

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}