@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.description_text {
    @include new-caption;
    font-style: italic;
    padding: 4px 0 8px;
    color: map-get($new-color_blue, "normal");
}

form[name="payment-details-form"] {
    [class*="col-"] {
        @include sm-up {
            padding: 8px;
        }
    }
}