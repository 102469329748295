@import "~@anytimefitness/hc-shared-styles/src/base/typography";
@import "~@anytimefitness/hc-shared-styles/src/base/colors";

.warning-box {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid rgba(map_get($new-color_yellow, "bright"), 0.3);
    background: rgba(map_get($new-color_yellow, "bright"), 0.1);

    &__icon-container {
        display: flex;
        align-items: flex-start;
        align-self: stretch;
    }

    &__icon {
        color: map_get($new-color_orange, "additional") !important;
    }

    &__text-container {
        display: grid;
        gap: 4px;
    }

    &__title {
        @include new-caption;
        font-weight: $bold_font-weight;
        color: map_get($new-color_grey, "dark");
    }

    &__description {
        @include new-caption;
        color: map_get($new-color_grey, "dark");
    }
}