@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.quotes-section {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 80px;
    width: 100%;
    padding: 0px 96px;

    @include lg-down {
        padding: 0px;
    }


    &__description-wrapper {
        display: flex;
        flex-direction: column;
        //padding: 0px 96px;
        gap: 32px;
        width: 100%;
        max-width: 1712px; //1392px+160px+160px
        margin: 0 auto;
    }

    &__description {
        @include new-h3;
        text-align: center;
        color: map_get($new-color_grey, "dark");
        width: 100%;

        @include md-down {
            text-align: initial;
        }
    }

    &__item-wrapper {
        width: 100%;
        max-width: 1712px; //1312px+200px+200px
        margin: 0 auto;

        @include md-only {
            padding: 24px;
        }

        @include md-down {
            padding: 8px;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        padding: 16px 0px 32px;
        gap: 24px;
        width: 100%;
        border-bottom: 1px;
        border-style: solid;
        border-color: map_get($new-color_blue, "normal");
    }

    &__header {
        width: 100%;
        @include new-h2;
        font-weight: $black_font-weight;
        text-align: center;
        color: map_get($new-color_blue, "normal");

        @include md-down {
            text-align: initial;
        }
    }

    &__quotes {
        width: 32px;
        height: 32px;
    }

    &__item-text {
        display: flex;
        @include new-body1;
        color: map_get($new-color_grey, "dark");
        padding: 0px 0px 16px;
    }

    &__item-signature {
        display: flex;
        @include new-h3;
        font-weight: $black_font-weight;
        color: map_get($new-color_grey, "dark");

        @include md-down {
            font-weight: $bold_font-weight;
        }
    }
}