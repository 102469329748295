@import "~@anytimefitness/hc-shared-styles/src/base/typography";
@import "~@anytimefitness/hc-shared-styles/src/base/colors";


.new-info-box {
  width: 100%;

  &__table-container {
    width: 100%;
    border: 1px solid map_get($new-color_blue, 'light');
    border-radius: 4px;
  }

  &__table-container-scrollable {
    overflow-x: auto;
    overflow-y: auto;
    margin: 0 1px 1px 1px;
    max-height: 573px;
  }

  &__table-container-nonscrollable {
    overflow-x: hidden;
    overflow-y: hidden;
    margin: 0 1px 1px 1px;
  }

  &__table-text-container {
    display: flex;
    justify-content: center;
    padding-top: 32px;
    color: map_get($new-color_blue, "normal");
    @include new-body2;
  }

  &__table {
    min-width: 1098px;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    &-row {
      &__white {
        background-color: map-get($new-color_white, 'white');
        border-bottom: 1px solid map_get($new-color_celadon, 'normal');

        &:hover {
          background-color: map-get($new-color_celadon, 'light');
          border-bottom: 1px solid map_get($new-color_celadon, 'normal');
        }
      }

      &__red {
        background-color: map_get($new-color_red, "light");
        border-bottom: 1px solid map_get($new-color_red, "middle");

        &:hover {
          background-color: map_get($new-color_red, "normal");
          border-bottom: 1px solid map_get($new-color_red, "dark");
        }
      }

      &__yellow {
        background-color: map_get($new-color_yellow, "light");
        border-bottom: 1px solid map_get($new-color_red, "middle");

        &:hover {
          background-color: map_get($new-color_yellow, "normal");
          border-bottom: 1px solid map_get($new-color_yellow, "dark");
        }
      }
    }

    &-cell {
      padding-left: 16px;
      color: map_get($new-color_blue, "normal");
      @include new-body2;
      height: 52px;
      vertical-align: middle;
      white-space: pre-wrap;
      background-color: inherit;
      border-bottom: inherit;
      text-align: left;

      &-head-content {
        display: flex;
        flex-direction: row;
        gap: 4px;
        max-width: inherit;
      }

      &:last-child {
        padding-right: 16px;
      }
    }

    thead th {
      div {
        display: flex;
        align-items: center;
        @include new-overline;
        color: map_get($new-color_blue, "normal");

        .new-arrow {
          display: inline-grid;
          row-gap: 1px;
          min-width: 8px;
        }

        .new-info-box__container {
          display: flex;
          align-items: center;
          @include new-overline;
          color: map_get($new-color_blue, "normal");
        }
      }
    }

    thead tr {
      box-shadow: 0px 1px 0px map_get($new-color_blue, "light");
      background-color: map-get($new-color_white, 'white');
    }
  }

  &__table-records-count-container {
    display: flex;
    gap: 16px;
    align-self: stretch;
    align-items: center;
  }

  &__table-records-count {
    color: map_get($new-color_blue, "normal");
    @include new-overline;
  }

  &__table-cell_center-text {
    text-align: center;
  }

  &__table-footer {
    display: flex;
    width: 100%;
    height: 80px;
    padding: 16px;
    border-radius: 0px 0px 4px 4px;
    border-right: 1px solid map_get($new-color_green, "light");
    border-bottom: 1px solid map_get($new-color_green, "light");
    border-left: 1px solid map_get($new-color_green, "light");
    background: #F9FAFB;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
  }

  &__table-cell-sort-icon-container {
    min-width: 16px;
    height: 16px;
    justify-content: center;
  }

  &__search-cell {
    margin: 10px 20px;
    justify-content: center;
  }

  &__search-box {
    height: 40px !important;
    background: map_get($new-color_white, "white");
  }

  ::-webkit-scrollbar {
    background: map-get($new-color_blue, 'lighter');
  }

  ::-webkit-scrollbar-track {
    background: map-get($new-color_blue, 'lighter');
  }

  ::-webkit-scrollbar-corner {
    background: map-get($new-color_blue, 'lighter');
  }
}

::-webkit-scrollbar {
  background: map-get($new-color_white, 'white');
  width: 15px;
  height: 15px;
}

::-webkit-scrollbar-track {
  background: map-get($new-color_white, 'white');
  margin-block-start: 52px
}

::-webkit-scrollbar-thumb {
  border: 5px solid transparent;
  border-radius: 100px;
  background-color: map_get($new-color_green, "light");
  -webkit-background-clip: content-box;
  background-clip: content-box;
}