@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.search-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;
    border: 1px solid map_get($new-color_blue, "light");
    border-radius: 4px;
    height: 56px;
    width: 100%;

    &:hover {
        border-color: map_get($new-color_blue, "normal");

        .search-box__search-icon,
        .search-box__search-input,
        .search-box__search-input::placeholder {
            color: map_get($new-color_blue, "normal");
        }
    }

    &__search-icon-container {
        display: flex;
        align-items: center;
        background: $color_white;
    }

    &__search-icon {
        color: map_get($new-color_blue, "middle");
    }

    &__filter-container {
        display: flex;
        align-items: center;
        background: $color_white;
        padding-left: 16px;
        border-left: solid 1px map_get($new-color_blue, "light");
    }

    &__filter-icon {
        cursor: pointer;
        color: map_get($new-color_blue, "middle");
    }

    &__clear-icon {
        cursor: pointer;
        color: map_get($new-color_blue, "normal");
    }

    &__search-input {
        outline: none;
        color: map_get($new-color_blue, "normal");
        @include new-body1;
        width: 100%;
    }

    &__column-container--dynamic {
        width: 100%;
    }

    &__column-container--fixed {
        display: flex;
        flex-direction: column;
        padding: 4px 8px;
    }

    &__tooltip-container {
        position: relative;
        width: 100%;
        height: 0px;
        background: map_get($new-color_white, "white");
    }

    &__tooltip-text {
        width: 100%;
        position: absolute;
        top: 4px;
        color: map_get($new-color_blue, "normal");
        border: 1px solid map_get($new-color_blue, "normal");
        border-radius: 4px;
        background: map_get($new-color_white, "white");
        pointer-events: none;
        font-weight: 400 !important;
        text-transform: none !important;
        @include new-caption;
        padding: 5px 4px;
    }

    .MuiBadge-badge {
        background-color: map_get($new-color_blue, "normal");
        color: $color_white;
    }
}