@import "~@anytimefitness/hc-shared-styles/src/base/colors";
@import "~@anytimefitness/hc-shared-styles/src/base/typography";
@import "~@anytimefitness/hc-shared-styles/src/utils/utils";

.dashboard {
    &__description>* {
        margin-top: 1rem !important;
    }

    &__content {
        &__widget {
            padding: 16px 0;

            &__box_icon {
                color: $color_black;

                &_blue {
                    color: map_get($new-color_blue, "middle");
                }
            }

            &_header {
                @include new-h4;
                font-weight: $bold_font-weight;
            }

            &__body {
                display: flex;
                flex-direction: column;
                height: 100%;
                margin-top: 10px;
                @include new-body2;

                &__blue {
                    color: map_get($new-color_blue, "middle");
                }

                &__row_label {
                    color: map_get($new-color_blue, "normal");
                    font-weight: $bold_font-weight;
                    text-transform: uppercase;
                }

                &__actions {
                    margin-top: auto;

                    >button {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}